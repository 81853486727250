import React, { useEffect, useState } from 'react'
import './style.css'
import Header from '../layout/header'
import { useMutation, useQuery } from '@apollo/client'
import { GET_ALL_USERS } from '../graphql/query'
import { RiCloseLine } from 'react-icons/ri'
import { CREATE_USER, DELETE_USER, UPDATE_USER } from '../graphql/mutation'

export default function Home({user}) {
    const [create, setCreate] = useState(false)
    const [edit, setEdit] = useState(false)

    const {loading, data} = useQuery(GET_ALL_USERS)

  return (
    <>
        <div className='Answer'>
          <Header title={"Users"} setCreate={setCreate} />
          <span>
          {loading ?
            <div className="Loading">
              Loading...
            </div>
            :
            <div className="Answer_Con">
              <table>
                <thead>
                  <tr>
                    <th style={{ borderLeft: 0 }}>Name</th>
                    <th>Email</th>
                    <th>Role</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.listUser?.map((item, index) => {
                    return (
                      <tr key={index} onClick={() => setEdit(item)}>
                        <td style={{ borderLeft: 0 }}>{item?.name}</td>
                        <td>{item?.email}</td>
                        <td>{item?.role}</td>
                        <td style={{ color: item?.status === 'Active' ? "#6dc964" : "red" }}>{item?.status}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          }
          </span>
        </div>
        <div className={create ? "Modal Modal_Show" : "Modal"}>
          <Create setCreate={setCreate} />
        </div>
        <div className={edit ? "Modal Modal_Show" : "Modal"}>
          <Edit edit={edit} setEdit={setEdit} />
        </div>
      </>
  )
}

function Create({ setCreate }) {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [role, setRole] = useState("")
  const [level, setLevel] = useState("")
  const [password, setPassword] = useState("")
  const [admin, setAdmin] = useState("")

  const [create, {loading}] = useMutation(CREATE_USER,{
    refetchQueries:[
      {query:GET_ALL_USERS}
    ]
  })

  const onCreate = async () => {
    if(name && email && password && role && level){
      create({
        variables:{
          "user": {
            "email": email,
            "password": password,
            "name": name,
            "role": role,
            "status": "Active",
            "level": level,
            "admin": admin
          }
        }
      }).then((e)=>{
        setCreate(false)
      }).catch((err)=>{
        alert(err?.message)
      })
    }
  }

  return (
    <div className="Modal_Con">
      <RiCloseLine className="Modal_Close" size={18} onClick={() => setCreate(false)} />
      <h2>Create User</h2>
      <form onSubmit={(e) => {
        e.preventDefault();
        onCreate()
      }}>
        <div className="Modal_Input_Con">
          <input style={{ marginTop: 1 }} value={name} onChange={(e) => setName(e.target.value)} placeholder="Full Name" />
          <input style={{ marginTop: 1 }} type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" />
          <input value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
          <select value={role} onChange={(e) => setRole(e.target.value)}>
            <option hidden>Select Role</option>
            <option>Helpful</option>
            <option>Together</option>
            <option>Innovation</option>
            <option>Relational</option>
            <option>Courageous</option>
            <option>Spiritual</option>
          </select>
          <select style={{marginBottom:5}} value={level} onChange={(e) => setLevel(e.target.value)}>
            <option hidden>Select Level</option>
            <option>Lead</option>
            <option>User</option>
            <option>Staff</option>
          </select>
          <select style={{marginBottom:5}} value={admin} onChange={(e) => setAdmin(e.target.value)}>
            <option hidden>Select Admin (optional)</option>
            <option value={'GV'}>Global Edit</option>
            <option value={'GE'}>Global View</option>
          </select>
        </div>
        <div className="Modal_Btn_Con">
          <button style={{ opacity: email && role && password && name ? 1 : 0.7 }}>{loading ? "Creating" : "Create"}</button>
        </div>
      </form>
    </div>
  )
}

function Edit({ edit, setEdit }) {
  const [data, setData] = useState({})
  const [del, setDel] = useState(false)

  useEffect(() => {
    setData(edit)
    setDel(false)
  }, [edit])

  const [update,{loading}] = useMutation(UPDATE_USER,{
    refetchQueries:[
      {query: GET_ALL_USERS}
    ]
  })

  const [deletes,deleted] = useMutation(DELETE_USER,{
    refetchQueries:[
      {query: GET_ALL_USERS}
    ]
  })

  const onDelete = ()=>{
    deletes({
      variables:{
        "deleteUserId": data?._id
      }
    }).then((e)=>{
      setEdit(false)
    }).catch((err)=>{
      alert(err?.message)
    })
  }

  const onCreate = async () => {
    update({
      variables:{
        "user": {
          "id": data?._id,
          "name": data?.name,
          "role": data?.role,
          "status": data?.status,
          "level": data?.level
        }
      }
    }).then((e)=>{
      setEdit(false)
    }).catch((err)=>{
      alert(err?.message)
    })
  }

  const onChange = (val, id) => {
    setData(prevAnswers => ({
      ...prevAnswers,
      [id]: val,
    }));
  }

  return (
    <div className="Modal_Con">
      <RiCloseLine className="Modal_Close" size={18} onClick={() => setEdit(false)} />
      <h2>Edit User</h2>
      <form onSubmit={(e) => {
        e.preventDefault();
      }}>
        <div className="Modal_Input_Con">
          <input style={{ marginTop: 1 }} value={data?.name} onChange={(e) => onChange(e.target.value, 'name')} placeholder="Full Name" />
          <select style={{ marginTop: 1 }} value={data?.role} onChange={(e) => onChange(e.target.value, 'role')}>
            <option hidden>Select Role</option>
            <option>Helpful</option>
            <option>Together</option>
            <option>Innovation</option>
            <option>Relational</option>
            <option>Courageous</option>
            <option>Spiritual</option>
          </select>
        </div>
        <div style={{marginTop:0}} className="Modal_Input_Con">
        <select style={{marginBottom:5}} value={data?.level} onChange={(e) => onChange(e.target.value, 'level')}>
            <option hidden>Select Level</option>
            <option>Lead</option>
            <option>User</option>
            <option>Staff</option>
          </select>
          </div>
        <div className="Modal_Btn_Con">
          <select value={data?.status} onChange={(e) => onChange(e.target.value, 'status')}>
            <option>Active</option>
            <option>Inactive</option>
          </select>
          <button style={{ backgroundColor:"red"}} onClick={()=>del? onDelete() : setDel(true)}>{deleted?.loading? "Deleting" : del? "Sure?" : "Delete"}</button>
          <button onClick={()=>onCreate()} style={{ opacity: data?.role && data?.name ? 1 : 0.7 }}>{loading ? "Updating" : "Update"}</button>
        </div>
      </form>
    </div>
  )
}