import { gql } from "@apollo/client";

export const GET_USER = gql`
query GetAdmin {
  getAdmin {
    name
  }
}`

export const GET_ALL_USERS = gql`
query ListUser {
  listUser {
    _id
    name
    role
    status
    email
    level
  }
}`

export const GET_REPORTS = gql`
query GetReports($month: String) {
  getReports(month: $month) {
    _id
    reports
    month
    role
  }
}`

export const GET_STAFF_REPORT = gql`
query GetStaffReports($month: String) {
  getStaffReports(month: $month) {
    _id
    reports
  }
}`

export const GET_YEAR_TARGET = gql`
query GetTargets {
  getTargets {
    _id
    targets
    role
  }
}`

export const GET_MONTH_REPORT = gql`
query GetMonthReports($months: [String]) {
  getMonthReports(months: $months) {
    _id
    month
    reports
    role
  }
  getTargets {
    _id
    targets
    role
  }
}`

export const GET_MONTH_TARGET = gql`
query GetMonthTargets {
  getMonthTargets {
    _id
    month
    role
    targets
  }
}`

export const GET_TOP_REPORT = gql`
query GetMonthReports($months: [String]) {
  getMonthReports(months: $months) {
    _id
    month
    reports
    role
  }
  getMonthTargets {
    _id
    month
    role
    targets
  }
  getTargets {
    _id
    targets
    role
  }
}`