'use client'
import React, { useEffect, useState } from 'react'
import './style.css'
import Header from '../layout/header'
import { useMutation, useQuery } from '@apollo/client'
import { GET_REPORTS, GET_STAFF_REPORT } from '../graphql/query'
import { RiCloseLine } from 'react-icons/ri'
import { DELETE_STAFF_REPORT, UPDATE_STAFF_REPORT } from '../graphql/mutation'

export default function Staff() {
    const date = new Date()
    const [month, setMonth] = useState(date?.toLocaleString('default', { month: 'long' }))
    const [show, setShow] = useState(false)

    const year = date?.getFullYear();

    const formattedDate = month + '-' + year;

    const { data, loading } = useQuery(GET_STAFF_REPORT, {
        variables: {
            month: formattedDate
        }
    })
    const [filter, setFilter] = useState(false)
    const [datas, setDatas] = useState([])

    const applyFilters = (answers, filters) => {
        return answers.filter(user => {

            return filters.every(filter => {
                const allAns = user?.reports ? JSON.parse(user?.reports) : null
                const answer = allAns[filter.field]

                if (!answer) return false;

                const filterValue = filter.value;

                switch (filter.condition) {
                    case '>':
                        return parseFloat(answer) > parseFloat(filterValue);
                    case '<':
                        return parseFloat(answer) < parseFloat(filterValue);
                    case '=':
                        return answer === filterValue;
                    case '==':
                        return parseFloat(answer) == parseFloat(filterValue);
                    case '>=':
                        return parseFloat(answer) >= parseFloat(filterValue);
                    case '<=':
                        return parseFloat(answer) <= parseFloat(filterValue);
                    case '!=':
                        return answer !== filterValue;
                    default:
                        return false;
                }
            });
        });
    };

    return (
        <>
            <div className='Answer'>
                <Header title={'Staff Report'} setCreate={setFilter} month={month} setMonth={setMonth} />
                <span>
                    {loading ?
                        <div className="Loading">
                            Loading...
                        </div>
                        :
                        <div className='Answer_Con'>
                            {reportDatas?.map((item, index) => {
                                const getAns = (id, report) => {
                                    const dat = report ? JSON.parse(report) : null
                                    return report ? dat[id] : null
                                }
                                const getAnsyn = (id, report) => {
                                    const dat = report ? JSON.parse(report) : null
                                    return report ? dat[id] == 'y' ? "Yes" : dat[id] == 'n' ? "No" : "" : null
                                }
                                return (
                                    <div className='Answer_Con1' key={index}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th style={{ borderLeft: 0 }}>Questions</th>
                                                    {datas?.length > 0 ?
                                                        applyFilters(data?.getStaffReports, datas)?.map((itms, inds) => {
                                                            return (
                                                                <th style={{ width: 200 }} key={inds}></th>
                                                            )
                                                        })
                                                        :
                                                        data?.getStaffReports?.map((itm, ind) => {
                                                            return (
                                                                <th style={{ width: 200 }} key={ind}></th>
                                                            )
                                                        })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {item?.sections?.map((itm, ind) => {
                                                    return (
                                                        <tr key={ind}>
                                                            <td style={{ borderLeft: 0, fontWeight: "700" }}>{itm?.question}</td>
                                                            {datas?.length > 0 ?
                                                                applyFilters(data?.getStaffReports, datas)?.map((itms, inds) => {
                                                                    return <td key={inds} style={{ width: 100 }}>{itm?.inType === "yn" ? getAnsyn(itm?.id, itms?.reports) : getAns(itm?.id, itms?.reports) || '--'}</td>
                                                                })
                                                                :
                                                                data?.getStaffReports?.map((itms, inds) => {
                                                                    return <td key={inds} style={{ width: 100 }} onClick={()=>setShow(data?.getStaffReports[inds])}>{itm?.inType === "yn" ? getAnsyn(itm?.id, itms?.reports) : getAns(itm?.id, itms?.reports) || '--'}</td>
                                                                })}
                                                        </tr>
                                                    )
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                )
                            })}
                        </div>
                    }
                </span>
            </div>
            <div className={filter ? 'Modal Modal_Show' : 'Modal'}>
                <Filters setShow={setFilter} datas={datas} setDatas={setDatas} />
            </div>
            <div className={show? 'Modal Modal_Show' : 'Modal'}>
                <Edit setShow={setShow} show={show} formattedDate={formattedDate}/>
            </div>
        </>
    )
}

function Edit({ setShow, show, formattedDate }) {
    const [data, setData] = useState({})

    useEffect(()=>{
        if(show?.reports){
            const val = JSON.parse(show?.reports)
            setData(val)
        }
        setDel(false)
    },[show])

    const update = (id, answer) => {
        setData(prevAnswers => ({
          ...prevAnswers,
          [id]: answer,
        }));
    }

    const [updates, updateData] = useMutation(UPDATE_STAFF_REPORT,{
        refetchQueries:[
            {
                query: GET_STAFF_REPORT,
                variables:{
                    month: formattedDate
                }
            }
        ]
    })

    const onCreate = () => {
        updates({
            variables: {
                "updateStaffReportId": show?._id,
                "report": {
                    "reports": data ? JSON.stringify(data) : ''
                }
            }
        }).then(() => {
            setShow(false)
        })
    }

    const [deletes, deleting] = useMutation(DELETE_STAFF_REPORT,{
        refetchQueries:[
            {
                query: GET_STAFF_REPORT,
                variables:{
                    month: formattedDate
                }
            }
        ]
    })

    const [del, setDel] = useState(false)

    const onDelete = () => {
        deletes({
            variables:{
                "deleteStaffReportId": show?._id
            }
        }).then(()=>{
            setShow(false)
        })
    }

    return(
        <div style={{width:550}} className="Modal_Con">
            <RiCloseLine className="Modal_Close" size={18} onClick={() => setShow(false)} />
            <h2>Edit Details</h2>
            <div className="Modal_Input_Con" style={{maxHeight:400}}>
                {reportDatas[0]?.sections?.map((item, index)=>{
                    return(
                        <div key={index} className='Modal_Input_Con3'>
                            <p>{item?.question}</p>
                            <div className='FlexCon'/>
                            {item?.inType === 'dropdown'?
                            <select value={data[item?.id]} onChange={(e)=>update(item?.id, e?.target?.value)}>
                                {item?.options?.map((itm, ind)=>{
                                    return <option key={ind}>{itm}</option>
                                })}
                            </select>
                            :
                            <input value={data[item?.id]} type={item?.type} onChange={(e)=>update(item?.id, e?.target?.value)}/>
                            }
                        </div>
                    )
                })}
            </div>
            <div className="Modal_Btn_Con">
                <button style={{marginTop:0, backgroundColor:"red"}} onClick={() =>del? onDelete() : setDel(true)}>{deleting?.loading? "Deleting" : del? "Are you sure?" : "Delete"}</button>
                <button style={{marginTop:0}} onClick={() => onCreate()}>{updateData?.loading? "Updating" : "Update"}</button>
            </div>
        </div>
    )
}

function Filters({ setShow, datas, setDatas }) {
    const [field, setField] = useState("")
    const [condition, setCondition] = useState("")
    const [value, setValue] = useState("")

    return (
        <div className="Modal_Con">
            <RiCloseLine className="Modal_Close" size={18} onClick={() => setShow(false)} />
            <h2>Add Filter</h2>
            <div className='Modal_Filter_Con'>
                {datas?.map((item, index) => {
                    return (
                        <div key={index} className='Modal_Filter' onClick={() => setDatas(datas?.filter((e) => e !== item))}>
                            {item?.field} {item?.condition} {item?.value == 'y' ? "Yes" : item?.value == 'n' ? "No" : item?.value}
                        </div>
                    )
                })}
            </div>
            <div style={{ paddingBottom: 1 }} className="Modal_Input_Con2">
                <select value={field} onChange={(e) => setField(e?.target?.value)}>
                    <option value={''}>Field name</option>
                    {reportDatas[0]?.sections?.filter((e) => e?.type === 'number' || e?.inType === 'yn')?.map((itm, ind) => {
                        return (
                            <option value={itm?.id} key={ind}>{itm?.question}</option>
                        )
                    })}
                </select>
                {reportDatas[0]?.sections?.find((e) => e?.id === field)?.type === 'number' ?
                    <>
                        <select disabled={!field} value={condition} onChange={(e) => setCondition(e?.target?.value)}>
                            <option value={''} hidden>Condition</option>
                            <option>==</option>
                            <option>{">"}</option>
                            <option>{"<"}</option>
                        </select>
                        <input disabled={!field} value={value} onChange={(e) => setValue(e?.target?.value)} placeholder='Value' />
                        <button onClick={() => {
                            setDatas((prev) => [...prev, { field, condition, value }])
                            setTimeout(() => {
                                setField('')
                                setCondition('')
                                setValue('')
                            }, 200);
                        }}>Add</button>
                    </>
                    :
                    <>
                        <select disabled={!field} value={value} onChange={(e) => setValue(e?.target?.value)}>
                            <option value={''} hidden>Condition</option>
                            <option value={'y'}>Yes</option>
                            <option value={'n'}>No</option>
                        </select>
                        <button onClick={() => {
                            setDatas((prev) => [...prev, { field, condition: '=', value }])
                            setTimeout(() => {
                                setField('')
                                setCondition('')
                                setValue('')
                            }, 200);
                        }}>Add</button>
                    </>
                }
            </div>
            <div className="Modal_Btn_Con">
                <button onClick={() => setShow(false)}>Show result</button>
            </div>
        </div>
    )
}

const reportDatas = [
    {
        id: "", sections: [
            { id: "subBy", question: "Submitted By", type: "" },
            { id: "detailAct", question: "Details/Name of Activity", type: "" },
            { id: "typeAct", question: "Type of Activity", inType: "dropdown", options: ["Jon Jackal", "Ministry", "Schools Mission Weeks", "Underground", "Event", "Residential", "Festival Camp", "Mission", "Centre Visit", "Training", "Retreat", "Meeting", "Church Service", "Support Call", "Gathering", "Learning Community", "International"] },
            { id: "typeEng", question: "Type of Engagement", inType: "dropdown", options: ["Virtual", "Face to face"] },
            { id: "dateAct", question: "Date of Activity From", type: "date" },
            { id: "dateActTo", question: "Date of Activity To", type: "date" },
            { id: "targetPop", question: "Primary Target Population", inType: "dropdown", options: ["Church Leaders", "Young People", "Youth Workers", "Trustees and Boards", "Centre Directors and Staff", "Volunteers", "Other Organisations", "Others"] },
            { id: "noReach", question: "Number of People Reached (Please report “0” if not relevant to activity.)", type: "number" },
            //.  This is how many people were involved in the activity, irrespective of age
            { id: "noYoungReach", question: "Number of Young People Reached (Please report “0” if not relevant to activity.)", type: "number", tip:"No. of young people present at the event?" },
            { id: "noYoungOpp", question: "Number of Young People given an opportunity to become a follower of Jesus Christ (please report “0” if not relevant to the activity).", type: "number", tip:"If opportunity given how many young people were invited to make an informed decision?" },
            { id: "noYoungDeci", question:"Number of young people who made a decision to follow Jesus?", type:"Number", tip:"How many young people positively responded to the gospel opportunity?"},
            { id: "notesField", question: "Notes from the Field", type: "" },
            // { id: "isCentre", question: "Was this with a Centre?", inType: "yn" },
        ]
    }
]