import React, { useState } from 'react'
import './style.css'
import { useMutation } from '@apollo/client'
import { ON_LOGIN } from '../graphql/mutation'

export default function LogIn() {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const [login,{loading}] = useMutation(ON_LOGIN)

  const onLogIn = async () => {
    if(email && password){
      login({
        variables:{
          "login": {
            "email": email,
            "password": password
          }
        }
      }).then((e)=>{
        localStorage.setItem("LT_Admin", e?.data?.onAdminLogin)
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      }).catch((err)=>{
        alert("Invalid Credentials")
      })
    }
  }

  return (
    <div className='Login'>
        <div className='Login_Con'>
            <h1>Log In</h1>
            <h2>to British LT admin</h2>
            <form onSubmit={(e)=>{
              e.preventDefault()
              if(email && password){
                onLogIn()
              }
            }}>
                <label>Email Address</label>
                <input value={email} onChange={(e)=>setEmail(e?.target?.value)} type='email' placeholder='Eg.hello@yfc.co.uk'/>
                <label>Password</label>
                <input value={password} onChange={(e)=>setPassword(e?.target?.value)} type='password' placeholder='• • • • • • • •'/>
                <button>{loading? "Please Wait" : "Log In"}</button>
            </form>
        </div>
    </div>
  )
}
