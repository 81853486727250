import React, { useEffect, useState } from 'react'
import './style.css'
import Header from '../layout/header'
import { useMutation, useQuery } from '@apollo/client'
import { GET_MONTH_TARGET, GET_YEAR_TARGET } from '../graphql/query'
import { RiCloseLine } from 'react-icons/ri'
import { CREATE_TARGET, UPDATE_TARGET } from '../graphql/mutation'

export default function Target() {
    const [type, setType] = useState("Yearly")
    const [create, setCreate] = useState(false)
    const [createMonth, setCreateMonth] = useState(false)
    const [edit, setEdit] = useState(false)
    const QUERY = type === 'Yearly'? GET_YEAR_TARGET : GET_MONTH_TARGET
    
    const { loading, data } = useQuery(QUERY)

    return (
        <>
            <div className='Target'>
                <Header title={'Targets'} type={type} setType={setType}/>
                <span>
                    {loading ?
                        <div className="Loading">
                            Loading...
                        </div>
                        :
                        targetdatas?.map((item, index) => {
                            const GetAns = (id, ans) => {
                                const dat = ans ? JSON.parse(ans) : null
                                return ans ? dat[id] : null
                            }
                            return (
                                <div className='Target_Con' key={index}>
                                    <div className='Target_Con_Head'>
                                        <h2>{item?.id}</h2>
                                        {type === 'Yearly'?
                                        <button onClick={() => data?.getTargets?.find((e) => e?.role === item?.id) ? setEdit(item?.id) : setCreate(item?.id)}>{data?.getTargets?.find((e) => e?.role === item?.id) ? "Edit" : "Create"}</button>
                                        : 
                                        <button onClick={() => setCreateMonth(item?.id)}>{"Create Monthly Target"}</button>
                                        }
                                    </div>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th style={{ borderLeft: 0 }}>Questions</th>
                                                {type === 'Yearly'?
                                                <th>Yearly Target</th>
                                                : data?.getMonthTargets?.filter((e)=>e?.role === item?.id)?.map((itm, ind)=>{
                                                    return <th key={ind}>{itm?.month}</th>
                                                })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {item?.sections?.map((itm, ind) => {
                                                return (
                                                    <tr key={ind}>
                                                        <td style={{ borderLeft: 0 }}>{itm?.question}</td>
                                                        {type === 'Yearly'?
                                                        <td>{GetAns(itm?.id, data?.getTargets?.find((e) => e?.role === item?.id)?.targets)}</td>
                                                        : data?.getMonthTargets?.filter((e)=>e?.role === item?.id)?.map((itms, ind)=>{
                                                            return <td key={ind}>{GetAns(itm?.id, itms?.targets)}</td>
                                                        })}
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            )
                        })}
                </span>
            </div>
            <div className={createMonth ? "Modal Modal_Show" : "Modal"}>
                <CreateMonth datas={data?.getMonthTargets} create={createMonth} setCreate={setCreateMonth} />
            </div>
            <div className={create ? "Modal Modal_Show" : "Modal"}>
                <Create create={create} setCreate={setCreate} />
            </div>
            <div className={edit ? "Modal Modal_Show" : "Modal"}>
                <Edit data={data?.getTargets} edit={edit} setEdit={setEdit} />
            </div>
        </>
    )
}

function CreateMonth({create, setCreate, datas}){
    const date = new Date()
    const month = date?.toLocaleString('default', { month: 'long' })
    const year = date?.getFullYear();

    const [months, setMonths] = useState(`${month}-${year}`)
    const [data, setData] = useState({})
    const dat = datas?.find((e)=>e?.role === create && e?.month === months)

    const MONTH_QUERY = dat?._id? UPDATE_TARGET : CREATE_TARGET
    const [creates,{loading}] = useMutation(MONTH_QUERY,{
        refetchQueries:[
            {query:GET_MONTH_TARGET}
        ]
    })

    const onCreate = () => {
        creates({
            variables: {
                "updateTargetId": dat?._id,
                "target": {
                    "month": months,
                    "targets": JSON.stringify(data),
                    "role": create
                }
            }
        }).then(()=>{
            setCreate(false)
        })
    }

    const onChange = (val, id) => {
        setData(prevAnswers => ({
          ...prevAnswers,
          [id]: val,
        }));
    }

    const tempData = () => {
        let tempData = {}
        targetdatas?.find((e) => e.id === create)?.sections?.forEach(item => {
                tempData[item.id] = "";
        });
        return tempData
    }

    useEffect(()=>{
        if(dat?.targets){
            setData(JSON.parse(dat?.targets))
        }else{
            setData(tempData())
        }
    },[create, months])

    return (
        <div className="Modal_Con">
            <RiCloseLine className="Modal_Close" size={18} onClick={() => setCreate(false)} />
                <div className='Modal_Head'>
                    <h2>Create Target</h2>
                    <select value={months} onChange={(e)=>setMonths(e.target.value)}>
                        <option hidden>Select Month</option>
                        {allMonths?.map((item, index)=>{
                            return <option key={index}>{item}</option>
                        })}
                    </select>
                </div>
            <div className='Modal_Input_Con'>
                {targetdatas?.find((e) => e?.id === create)?.sections?.map((itm, ind) => {
                    return (
                        <div key={ind} className='Modal_Input_Con1'>
                            <p>{itm?.question}</p>
                            <input value={data[itm?.id]} onChange={(e)=>onChange(e?.target?.value, itm?.id)} type='number' />
                        </div>
                    )
                })}
            </div>
            <div className="Modal_Btn_Con">
                <button onClick={()=>setData(tempData())} style={{backgroundColor:"#ddd", color:"#000"}}>Clear all</button>
                <button onClick={()=>onCreate()}>{loading? "Creating" : dat?._id? "Update" : "Create"}</button>
            </div>
        </div>
    )
}

function Create({ create, setCreate }) {
    const [data, setData] = useState({})
    const [creates,{loading}] = useMutation(CREATE_TARGET,{
        refetchQueries:[
            {query:GET_YEAR_TARGET}
        ]
    })

    const onCreate = () => {
        creates({
            variables: {
                "target": {
                    "year": "2024-2025",
                    "targets": JSON.stringify(data),
                    "role": create
                }
            }
        }).then(()=>{
            setCreate(false)
        })
    }

    const onChange = (val, id) => {
        setData(prevAnswers => ({
          ...prevAnswers,
          [id]: val,
        }));
    }

    return (
        <div className="Modal_Con">
            <RiCloseLine className="Modal_Close" size={18} onClick={() => setCreate(false)} />
            <h2>Create Target (2024 - 2025)</h2>
            <div className='Modal_Input_Con'>
                {targetdatas?.find((e) => e?.id === create)?.sections?.map((itm, ind) => {
                    return (
                        <div key={ind} className='Modal_Input_Con1'>
                            <p>{itm?.question}</p>
                            <input value={data[itm?.id]} onChange={(e)=>onChange(e?.target?.value, itm?.id)} type='number' />
                        </div>
                    )
                })}
            </div>
            <div className="Modal_Btn_Con">
                <button onClick={()=>onCreate()}>{loading? "Creating" : "Create"}</button>
            </div>
        </div>
    )
}

function Edit({ edit, setEdit, data }) {
    const [datas, setData] = useState({})
    const [creates,{loading}] = useMutation(UPDATE_TARGET,{
        refetchQueries:[
            {query:GET_YEAR_TARGET}
        ]
    })

    useEffect(()=>{
        if(data?.find((e)=>e?.role === edit)?.targets){
            setData(JSON.parse(data?.find((e)=>e?.role === edit)?.targets))
        }
    },[data, edit])

    const onCreate = () => {
        creates({
            variables: {
                "updateTargetId": data?.find((e)=>e?.role === edit)?._id,
                "target": {
                    "targets": JSON.stringify(datas),
                }
            }
        }).then(()=>{
            setEdit(false)
        })
    }

    const onChange = (val, id) => {
        setData(prevAnswers => ({
          ...prevAnswers,
          [id]: val,
        }));
    }

    return (
        <div className="Modal_Con">
            <RiCloseLine className="Modal_Close" size={18} onClick={() => setEdit(false)} />
            <h2>Edit Target (2024 - 2025)</h2>
            <div className='Modal_Input_Con'>
                {targetdatas?.find((e) => e?.id === edit)?.sections?.map((itm, ind) => {
                    return (
                        <div key={ind} className='Modal_Input_Con1'>
                            <p>{itm?.question}</p>
                            <input value={datas[itm?.id]} onChange={(e)=>onChange(e?.target?.value, itm?.id)} type='number' />
                        </div>
                    )
                })}
            </div>
            <div className="Modal_Btn_Con">
                <button onClick={()=>onCreate()}>{loading? "Updating" : "Update"}</button>
            </div>
        </div>
    )
}

const targetdatas = [
    {
        id: "Helpful",
        sections: [
            { id: "noTrainEvents", question: "No. Training events", type: "number" },
        ]
    },
    {
        id: "Together",
        sections: [
            { id: "noGrantWritten", question: "No. Grants written & submitted", type: "number" },
            { id: "noNewPartners", question: "No. New partners", type: "number" },
            { id: "noNewSupporter", question: "No. New personal supporters", type: "number" },
            { id: "noDonors", question: "No. Major donors contacted", type: "number" },
            { id: "noDonorsVisit", question: "No. Major donors visited", type: "number" },
            { id: "noDonorsGiven", question: "No. Major donors given", type: "number" },
            { id: "noTFTDsub", question: "No. TFTD subscribers", type: "number" },
            { id: "noNewSubscriber", question: "No. New subscribers", type: "number" },
            { id: "noCourseSale", question: "No. MyLife Course sales", type: "number" },
            { id: "noBooksSale", question: "No. MyLife books sales", type: "number" },
            { id: "noAppointment", question: "No. MyLife appointments", type: "number" },
            { id: "noSeriesSent", question: "No. WYPM packs sent out", type: "number" },
            { id: "noSocialPost", question: "No. Social media posts", type: "number" },
            { id: "noPaidAd", question: "No. Paid advertising adverts", type: "number" },
        ]
    },
    {
        id: "Innovation",
        sections: [
            { id: "noSchoolContent", question: "No. School lessons/Assembly content produced", type: "number" },
            { id: "noCommunity", question: "No. Community content produced", type: "number" },
            { id: "noChurchContent", question: "No. Church content produced", type: "number" },
            { id: "noRequestReg", question: "ReQuest no. Registered teachers ", type: "number" },
            { id: "noStandProject", question: "No. Stand Along projects ", type: "number" },
            { id: "noTrainVideo", question: "No. Training Wall videos ", type: "number" },
        ]
    },
    {
        id: "Relational",
        sections: [
            { id: "noCentreNew", question: "No. new centres", type: "number" },
            { id: "noCentreContact", question: "No. Centre contacts", type: "number" },
            { id: "noCentreVisits", question: "No. Centre visits", type: "number" },
            { id: "noCentreTrustee", question: "No. Centre Trustee contacts/visits", type: "number" },
            { id: "noChurchVisit", question: "No. Church visits", type: "number" },
            { id: "noChurchFollowUp", question: "No. Church follow up visits", type: "number" },
            { id: "noPluseGather", question: "No. Pulse Gatherings - Total", type: "number" },
            { id: "noPluseLearn", question: "No. Pulse Learning Communities - Total", type: "number" },
            { id: "noUrbanEvent", question: "No. Urban Outreach events", type: "number" },
            { id: "noUrbanYouthEvent", question: "No. Urban youth leader events", type: "number" },
        ]
    },
    {
        id: "Spiritual",
        sections: [
            { id: "noPrayerPartner", question: "No. Prayer partners", type: "number" },
            { id: "noTFTDsub", question: "No. TFTD subscribers", type: "number" },
            { id: "noResearchLaunch", question: "Centre research launched", type: "number" },
            { id: "noInterResearchLaunch", question: "International research launched", type: "number" },
            { id: "noResourceInterLaunch", question: "Resources for International launched", type: "number" },
        ]
    },
    {
        id: "Courageous",
        sections: [
            { id: "noSocialResponse", question: "No. Advocacy social media responses", type: "number" },
            { id: "noPressEngage", question: "No. Advocacy Press engagements", type: "number" },
            { id: "noChurchEngage", question: "No. Denominational / church advocacy engagements", type: "number" },
            { id: "noStrategic", question: "No. of strategic bodies", type: "number" },
        ]
    }
]

const allMonths = ['April-2024', 'May-2024', 'June-2024', 'July-2024', 'August-2024', 'September-2024', 'October-2024', 'November-2024', 'December-2024', 'January-2025', 'February-2025', 'March-2025', 'April-2025', 'May-2025', 'June-2025', 'July-2025', 'August-2025']