'use client'
import React, { useEffect, useState } from 'react'
import './style.css'
import Header from '../layout/header'
import { useMutation, useQuery } from '@apollo/client'
import { GET_REPORTS } from '../graphql/query'
import { RiCloseLine } from 'react-icons/ri'
import { UPDATE_REPORT } from '../graphql/mutation'

export default function Answer() {
  const date = new Date()
  const [month, setMonth] = useState(date?.toLocaleString('default', { month: 'short' }))
  const { data, loading } = useQuery(GET_REPORTS)
  const [filter, setFilter] = useState(false)
  const [datas, setDatas] = useState([])
  const [edit, setEdit] = useState(false)

  const applyFilters = (answers, filters) => {
    return answers.filter(user => {

      return filters.every(filter => {
        const allAns = user?.reports ? JSON.parse(user?.reports) : {}
        const answer = allAns[filter.field]

        if (!answer) return false;

        const filterValue = filter.value;

        switch (filter.condition) {
          case '>':
            return parseFloat(answer) > parseFloat(filterValue);
          case '<':
            return parseFloat(answer) < parseFloat(filterValue);
          case '=':
            return answer === filterValue;
          case '==':
            return parseFloat(answer) == parseFloat(filterValue);
          case '>=':
            return parseFloat(answer) >= parseFloat(filterValue);
          case '<=':
            return parseFloat(answer) <= parseFloat(filterValue);
          case '!=':
            return answer !== filterValue;
          default:
            return false;
        }
      });
    });
  };

  return (
    <>
      <div className='Answer'>
        <Header title={'Answers'} setCreate={setFilter} month={month} setMonth={setMonth} />
        <span>
          {loading ?
            <div className="Loading">
              Loading...
            </div>
            :
            <div className='Answer_Con'>
              {reportDatas?.map((item, index) => {
                const getAns = (id, report) => {
                  const dat = report ? JSON.parse(report) : null
                  return report ? dat[id] : null
                }
                return (
                  <div className='Answer_Con1' key={index}>
                    <h2>{item?.id}</h2>
                    <table>
                      <thead>
                        <tr>
                          <th style={{ borderLeft: 0 }}>Questions</th>
                          {data?.getReports?.filter((e) => e?.role === item?.id)?.map((itm, ind) => {
                            return (
                              <th style={{ width: 200 }} key={ind} onClick={() => setEdit(itm)}>{itm?.month}</th>
                            )
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {item?.sections?.map((itm, ind) => {
                          return (
                            <tr key={ind}>
                              <td style={{ borderLeft: 0 }}>{itm?.question}</td>
                              {datas?.length > 0 ?
                                applyFilters(data?.getReports, datas)?.map((itms, inds) => {
                                  return <td key={inds} style={{ width: 100 }}>{getAns(itm?.id, itms?.reports) || '--'}</td>
                                })
                                :
                                data?.getReports?.filter((e) => e?.role === item?.id)?.map((itms, inds) => {
                                  return <td key={inds} style={{ width: 100 }}>{getAns(itm?.id, itms?.reports) || '--'}</td>
                                })}
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                )
              })}
            </div>
          }
        </span>
      </div>
      <div className={filter ? 'Modal Modal_Show' : 'Modal'}>
        <Filters setShow={setFilter} datas={datas} setDatas={setDatas} />
      </div>
      <div className={edit ? 'Modal Modal_Show' : 'Modal'}>
        <Edit setShow={setEdit} show={edit} />
      </div>
    </>
  )
}

function Edit({ setShow, show }) {
  const [data, setData] = useState({})

  useEffect(() => {
    if (show?.reports) {
      const val = JSON.parse(show?.reports)
      setData(val)
    }
  }, [show])

  const [create, updateData] = useMutation(UPDATE_REPORT, {
    refetchQueries: [
      { query: GET_REPORTS }
    ]
  })

  const onCreate = () => {
    create({
      variables: {
        "updateReportId": show?._id,
        "report": {
          "reports": data ? JSON.stringify(data) : ''
        }
      }
    }).then(() => {
      setShow(false)
    })
  }

  const update = (id, answer) => {
    setData(prevAnswers => ({
      ...prevAnswers,
      [id]: answer,
    }));
  }

  return (
    <div style={{ width: 550 }} className="Modal_Con">
      <RiCloseLine className="Modal_Close" size={18} onClick={() => setShow(false)} />
      <h2>Edit Details ({show?.role} {show?.month})</h2>
      <div className="Modal_Input_Con" style={{ maxHeight: 400 }}>
        {reportDatas?.find((e) => e?.id == show?.role)?.sections?.map((item, index) => {
          return (
            <div key={index} className='Modal_Input_Con3'>
              <p>{item?.question}</p>
              <div className='FlexCon' />
              {item?.inType === 'textarea'?
              <textarea value={data[item?.id]} type={item?.type} onChange={(e) => update(item?.id, e?.target?.value)} />
              :
              <input value={data[item?.id]} type={item?.type} onChange={(e) => update(item?.id, e?.target?.value)} />
              }
            </div>
          )
        })}
      </div>
      <div className="Modal_Btn_Con">
        <button style={{ marginTop: 0 }} onClick={() => onCreate()}>{updateData?.loading ? "Updating" : "Update"}</button>
      </div>
    </div>
  )
}

function Filters({ setShow, datas, setDatas }) {
  const [field, setField] = useState("")
  const [condition, setCondition] = useState("")
  const [value, setValue] = useState("")

  return (
    <div className="Modal_Con">
      <RiCloseLine className="Modal_Close" size={18} onClick={() => setShow(false)} />
      <h2>Add Filter</h2>
      <div className='Modal_Filter_Con'>
        {datas?.map((item, index) => {
          return (
            <div key={index} className='Modal_Filter' onClick={() => setDatas(datas?.filter((e) => e !== item))}>
              {item?.field} {item?.condition} {item?.value == 'y' ? "Yes" : item?.value == 'n' ? "No" : item?.value}
            </div>
          )
        })}
      </div>
      <div style={{ paddingBottom: 1 }} className="Modal_Input_Con2">
        <select value={field} onChange={(e) => setField(e?.target?.value)}>
          <option value={''}>Field name</option>
          {reportDatas[0]?.sections?.filter((e) => e?.type === 'number' || e?.inType === 'yn')?.map((itm, ind) => {
            return (
              <option value={itm?.id} key={ind}>{itm?.question}</option>
            )
          })}
        </select>
        {reportDatas[0]?.sections?.find((e) => e?.id === field)?.type === 'number' ?
          <>
            <select disabled={!field} value={condition} onChange={(e) => setCondition(e?.target?.value)}>
              <option value={''} hidden>Condition</option>
              <option>=</option>
              <option>{">"}</option>
              <option>{"<"}</option>
            </select>
            <input disabled={!field} value={value} onChange={(e) => setValue(e?.target?.value)} placeholder='Value' />
            <button onClick={() => {
              setDatas((prev) => [...prev, { field, condition, value }])
              setTimeout(() => {
                setField('')
                setCondition('')
                setValue('')
              }, 200);
            }}>Add</button>
          </>
          :
          <>
            <select disabled={!field} value={value} onChange={(e) => setValue(e?.target?.value)}>
              <option value={''} hidden>Condition</option>
              <option value={'y'}>Yes</option>
              <option value={'n'}>No</option>
            </select>
            <button onClick={() => {
              setDatas((prev) => [...prev, { field, condition: '=', value }])
              setTimeout(() => {
                setField('')
                setCondition('')
                setValue('')
              }, 200);
            }}>Add</button>
          </>
        }
      </div>
      <div className="Modal_Btn_Con">
        <button onClick={() => setShow(false)}>Show result</button>
      </div>
    </div>
  )
}

const reportDatas = [
  {
    id: "Courageous",
    sections: [
          { id: "noSocialResponse", question: "No. Advocacy social media responses (contrast against target)", type: "number" },
          // { id: "noSocialResponseTarget", question: "Target", type: "number" },
          { id: "noPressEngage", question: "No. Advocacy Press engagements (contrast against target)", type: "number" },
          // { id: "noPressEngageTarget", question: "Target", type: "number" },
          { id: "noChurchEngage", question: "No. Denominational / church advocacy relationships (contrast against target)", type: "number" },
          // { id: "noChurchEngageTarget", question: "Target", type: "number" },
          { type: "Empty" },
          { id: "noNewProject", question: "No. New Projects initiated (show last 3 months)", type: "number" },
          { id: "projectComment", question: "Any Comments", type: "", inType: 'textarea' },
          { id: "noNewPartner", question: "No. New partnerships", type: "number" },
          { id: "noExternal", question: "No. External appointments", type: "number" },
          { id: "partnerComment", question: "Any Comments", type: "", inType: 'textarea' },
      // {
      //   isAuto: true,
      //   data: [
      //     { id: "noYouthEvents", question: "No. Youth events (org wide)", type: "number", auto: true, staffOpt2: "Young People" },
      //     { id: "noYoungReach", question: "No. Young people reached (org wide)", type: "number", auto: true, staff: "noYoungReach" },
      //     { id: "noYoungOpport", question: "No. Young people given an opportunity to become a follower of Jesus (org wide)", type: "number", auto: true, staff: "noYoungOpp" },
      //     { id: "noYoungFollow", question: "No. Young people made a decision to follow Jesus (org wide)", type: "number", auto: true, staff: "noYoungDeci" },
      //     { id: "noYouthLeader", question: "No. Youth Leader events (org wide)", type: "number", auto: true, staffOpt2: "Youth Workers" },
      //     { id: "noYouthLeaderEngage", question: "No. Youth Leaders engaged with (org wide)", type: "number", auto: true, staffOpt2: "Youth Workers" },
      //     { id: "youthComment", question: "Any Comments", type: "", inType: 'textarea' },
      //   ]
      // },
      // {
      //   isAuto: true,
      //   data: [
      //     { id: "noArticles", question: "No. of articles written and published in external publications (org wide)", type: "number", auto: true },
      //     { id: "noPodcasts", question: "No. of podcasts recorded and uploaded (org wide)", type: "number", auto: true },
      //     { id: "mediaComment", question: "Any Comments", type: "", inType: 'textarea' },
      //   ]
      // },
          { id: "noStrategic", question: "No. of strategic bodies (contrast against target)", type: "number" },
          { id: "noStrategicComment", question: "Any Comments", type: "", inType: 'textarea' },
          { id: "noJonJacketHit", question: "No. Jon Jackal music plays", type: "number" },//, auto: true, staffOpt: "Jon Jackal"
          { id: "noJonJacketSale", question: "No. Jon Jackal Merch sales (units) ", type: "number" },
          { id: "noJonJacketInter", question: "No. of Jon Jackal meaningful interactions", type: "number" },
          { id: "noJonJacketViews", question: "No. of Jon Jackal views", type: "number" },
          { id: "jonComment", question: "Any Comments", type: "", inType: 'textarea' },
          { id: "noUndergroundViews", question: "No. of Underground views", type: "number" },//, auto: true, staffOpt: "Underground"
          { id: "noUndergroundInter", question: "No. Of Underground meaningful interactions", type: "number" },
          { id: "undergroundComment", question: "Any Comments", type: "", inType: 'textarea' },
          { id: "noSpeakEvent", question: "No of speaking at events", type: "number" },
          { id: "speakEventComment", question: "Any Comments", type: "", inType: 'textarea' },
          { id: "manageAccounts", question: "Management accounts for Courageous Surplus/Deficit £", type: "number" },
          { id: "accountComment", question: "Any Comments", type: "", inType: 'textarea' },
          { id: "comments", question: "Comments", inType: 'textarea', type: "number" },
          { id: "staffUpdates", question: "Staffing Updates", inType: 'textarea', type: "number" },
    ]
  },
  {
    id: "Helpful",
    sections: [
      { id: "incomeLast", question: "Income (last complete month) (The income total reflects the position at the close of the latest management accounts)", type: "number", currency: true },
      { id: "incomeBudget", question: "Income against budget", type: "number", currency: true },
      // { id: "incomeForecast", question: "Income against forecast", type: "number" }, 
      { id: "incomeComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "expenseLast", question: "Expense (last complete month) (The expenditure total reflects the position at the close of the latest management accounts)", type: "number", currency: true },
      { id: "expenseBudget", question: "Expense against budget", type: "number", currency: true },
      // { id: "expenseForecast", question: "Expense against forecast", type: "number" },
      { id: "expenseComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "generalCash", question: "General & Designated Cash £ (Month end position)", type: "number", currency: true },
      { id: "generalRestrict", question: "Restricted Cash £ (Month end position)", type: "number", currency: true },
      { id: "generalComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noCourStaffSick", question: "Number of staff sick (Courageous)", type: "number" },
      { id: "noCourSickDays", question: "Number of sick days taken (Courageous)", type: "number" },
      { id: "noCourStaffAL", question: "Number of staff on AL (Courageous)", type: "number" },
      { id: "noCourALDays", question: "Number of AL days taken (Courageous)", type: "number" },
      { id: "staffCourComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noHelpStaffSick", question: "Number of staff sick (Helpful)", type: "number" },
      { id: "noHelpSickDays", question: "Number of sick days taken (Helpful)", type: "number" },
      { id: "noHelpStaffAL", question: "Number of staff on AL (Helpful)", type: "number" },
      { id: "noHelpALDays", question: "Number of AL days taken (Helpful)", type: "number" },
      { id: "staffHelpComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noRelStaffSick", question: "Number of staff sick (Relational)", type: "number" },
      { id: "noRelSickDays", question: "Number of sick days taken (Relational)", type: "number" },
      { id: "noRelStaffAL", question: "Number of staff on AL (Relational)", type: "number" },
      { id: "noRelALDays", question: "Number of AL days taken (Relational)", type: "number" },
      { id: "staffRelComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noInovStaffSick", question: "Number of staff sick (Innovation)", type: "number" },
      { id: "noInovSickDays", question: "Number of sick days taken (Innovation)", type: "number" },
      { id: "noInovStaffAL", question: "Number of staff on AL (Innovation)", type: "number" },
      { id: "noInovALDays", question: "Number of AL days taken (Innovation)", type: "number" },
      { id: "staffInovComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noSprStaffSick", question: "Number of staff sick (Spiritual)", type: "number" },
      { id: "noSprSickDays", question: "Number of sick days taken (Spiritual)", type: "number" },
      { id: "noSprStaffAL", question: "Number of staff on AL (Spiritual)", type: "number" },
      { id: "noSprALDays", question: "Number of AL days taken (Spiritual)", type: "number" },
      { id: "staffSprComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noTogStaffSick", question: "Number of staff sick (Together)", type: "number" },
      { id: "noTogSickDays", question: "Number of sick days taken (Together)", type: "number" },
      { id: "noTogStaffAL", question: "Number of staff on AL (Together)", type: "number" },
      { id: "noTogALDays", question: "Number of AL days taken (Together)", type: "number" },
      { id: "staffTogComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noTogStaffSick", question: "Number of staff sick (Organisation)", type: "number", autoId: ["noCourStaffSick", "noHelpStaffSick", "noRelStaffSick", "noInovStaffSick", "noSprStaffSick", "noTogStaffSick"] },
      { id: "noTogSickDays", question: "Number of sick days taken (Organisation)", type: "number", autoId: ["noCourSickDays", "noHelpSickDays", "noRelSickDays", "noInovSickDays", "noSprSickDays", "noTogSickDays"] },
      { id: "noTogStaffAL", question: "Number of staff on AL (Organisation)", type: "number", autoId: ["noCourStaffAL", "noHelpStaffAL", "noRelStaffAL", "noInovStaffAL", "noSprStaffAL", "noTogStaffAL"] },
      { id: "noTogALDays", question: "Number of AL days taken (Organisation)", type: "number", autoId: ["noCourALDays", "noHelpALDays", "noRelALDays", "noInovALDays", "noSprALDays", "noTogALDays"] },
      { id: "noResignation", question: "No. Resignations", type: "number" },
      { id: "noNewStarter", question: "No. New starters", type: "number" },
      { id: "noRecruit", question: "No. Recruiting for posts", type: "number" },
      { id: "regComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noTrainEvents", question: "No. Training events (contrast against target)", type: "number" },
      // { id: "noTrainEventsTarget", question: "Target", type: "number" },
      { id: "trainingComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noOnlineSales", question: "No. Online shop orders", type: "number" },
      { id: "noProductOnlineSales", question: "No. Of products sold from online shop orders", type: "number" },
      { id: "salesComment", question: "Any Comments", type: "", inType: 'textarea' },
      // { id: "noYouthEvents", question: "No. Youth events (org wide)", type: "number" },
      // { id: "noYoungReach", question: "No. Young people reached (org wide)", type: "number" },
      // { id: "noYoungOpport", question: "No. Young people given an opportunity to become a follower of Jesus (org wide)", type: "number" },
      // { id: "noYoungFollow", question: "No. Young people made a decision to follow Jesus (org wide)", type: "number" },
      { id: "noYouthLeader", question: "No. Youth Leader events (org wide)", type: "number" },
      { id: "noYouthLeaderEngage", question: "No. Youth Leaders engaged with (org wide)", type: "number" },
      // { id: "youthComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noArticles", question: "No. of articles written and published in external publications (org wide)", type: "number" },
      { id: "noPodcasts", question: "No. of podcasts recorded and uploaded (org wide)", type: "number" },
      { id: "mediaComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noSpeakEvent", question: "No of speaking at events (org wide)", type: "number" },
      { id: "speakEventComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "manageAccounts", question: "Management accounts for Helpful surplus/deficit £ (This total reflects the position at the close of the latest management accounts)", type: "number", currency: true },
      { id: "accountComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "comments", question: "Comments", inType: 'textarea', type: "number" },
      { id: "staffUpdates", question: "Staffing Updates", inType: 'textarea', type: "number" },
    ]
  },
  {
    id: "Relational",
    sections: [
          { id: "noCentreNew", question: "No. new centres (contrast against target)", type: "number" },
          { id: "noNewCentre", question: "No. New Centre enquiries", type: "number" },
          { id: "noCentreContact", question: "No. Centre contacts (contrast against target)", type: "number" },
          // { id: "noCentreContactTarget", question: "Target", type: "number" },
          { id: "noCentreVisits", question: "No. Centre visits (contrast against target)", type: "number" },
          // { id: "noCentreVisitsTarget", question: "Target", type: "number" },
          { id: "noCentreTrustee", question: "No. Centre Trustee contacts/visits (contrast against target)", type: "number" },
          // { id: "noCentreTrusteeTarget", question: "Target", type: "number" },
          { id: "centreComment", question: "Any Comments", type: "", inType: 'textarea' },
          { id: "noChurchEnquiry", question: "No. Church enquiries", type: "number" },
          { id: "noChurchVisit", question: "No. Church Consultations (contrast against target)", type: "number" },
          // { id: "noChurchVisitTarget", question: "Target", type: "number" },
          { id: "noChurchFollowUp", question: "No. Church follow up meetings (contrast against target)", type: "number" },
          // { id: "noChurchFollowUpTarget", question: "Target", type: "number" },
          { id: "churchComment", question: "Any Comments", type: "", inType: 'textarea' },
          { id: "noWhyAppointment", question: "No. of Why Pack Opportunities/Appointments", type: "number" },
          { id: "noWhyPhone", question: "No. Of Why Pack phone calls", type: "number" },
          { id: "noPluseEnquiry", question: "No. Pulse enquiries", type: "number" },
          { id: "noPluseGather", question: "No. Pulse Gatherings Launched - Total (contrast against target)", type: "number" },
          // { id: "noPluseGatherTarget", question: "Target", type: "number" },
          { id: "noPluseLearn", question: "No. Pulse Learning Communities - Total (contrast against target)", type: "number" },
          // { id: "noPluseLearnTarget", question: "Target", type: "number" },
          // { id: "noColdCall", question: "No. Cold call follow ups", type: "number" },
          { id: "noPulseGatherCall", question: "No. of support calls to Pulse Gatherings", type: "number" },
          { id: "noPulseGatherVisit", question: "No. of in person visits to Pulse Gatherings", type: "number" },
          { id: "noPluseChurch", question: "No. of new churches engaged in Pulse", type: "number" },
          { id: "pluseComment", question: "Any Comments", type: "", inType: 'textarea' },
          { id: "noUrbanEvent", question: "No. Urban Outreach events (contrast against target)", type: "number" },
          // { id: "noUrbanEventTarget", question: "Target", type: "number" },
          { id: "noUrbanYouthEvent", question: "No. Urban youth leader events (contrast against target)", type: "number" },
          // { id: "noUrbanYouthEventTarget", question: "Target", type: "number" },
          { id: "urbanComment", question: "Any Comments", type: "", inType: 'textarea' },
          // { id: "noYouthEvents", question: "No. Youth events (org wide)", type: "number" },
          // { id: "noYoungReach", question: "No. Young people reached (org wide)", type: "number" },
          // { id: "noYoungOpport", question: "No. Young people given an opportunity to become a follower of Jesus (org wide)", type: "number" },
          // { id: "noYoungFollow", question: "No. Young people made a decision to follow Jesus (org wide)", type: "number" },
          { id: "noYouthLeader", question: "No. Youth Leader events (org wide)", type: "number" },
          { id: "noYouthLeaderEngage", question: "No. Youth Leaders engaged with (org wide)", type: "number" },
          // { id: "youthComment", question: "Any Comments", type: "", inType: 'textarea' },
          { id: "noArticles", question: "No. of articles written and published in external publications (org wide)", type: "number" },
          { id: "noPodcasts", question: "No. of podcasts recorded and uploaded (org wide)", type: "number" },
          { id: "mediaComment", question: "Any Comments", type: "", inType: 'textarea' },
          { id: "noSpeakEvent", question: "No of speaking at events (org wide)", type: "number" },
          { id: "speakEventComment", question: "Any Comments", type: "", inType: 'textarea' },
          { id: "manageAccounts", question: "Management accounts for Relational Surplus/Deficit £", type: "number" },
          { id: "accountComment", question: "Any Comments", type: "", inType: 'textarea' },
          { id: "comments", question: "Comments", inType: 'textarea', type: "number" },
          { id: "staffUpdates", question: "Staffing Updates", inType: 'textarea', type: "number" },
    ]
  },
  {
    id: "Innovation",
    sections: [
      { id: "noProjectDesign", question: "No. Projects assigned Design (show last 3 months)", type: "number" },
      { id: "noCompleteDesign", question: "No. Projects completed Design (show last 3 months)", type: "number" },
      { id: "noProjectVideo", question: "No. Projects assigned Video (show last 3 months)", type: "number" },
      { id: "noCompleteVideo", question: "No. Projects completed Video (show last 3 months)", type: "number" },
      { id: "projectComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noSchoolContent", question: "No. School lessons/Assembly content produced (contrast against target)", type: "number" },
      // { id: "noSchoolContentTarget", question: "Target", type: "number" },
      { id: "noCommunity", question: "No. Community content produced (contrast against target)", type: "number" },
      // { id: "noCommunityTarget", question: "Target", type: "number" },
      { id: "noChurchContent", question: "No. Church content produced (contrast against target)", type: "number" },
      // { id: "noChurchContentTarget", question: "Target", type: "number" },
      { type: "Empty" },
      { id: "noUniqueProject", question: "No. Stand alone / Unique projects produced (show last 3 months)", type: "number" },
      { id: "contentComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noRequestHit", question: "ReQuest hits (unique users) (show last 3 months)", type: "number" },
      { id: "noRequestReg", question: "No. Of new Request registered teachers (contrast against target) ", type: "number" },
      { id: "noRequestTotalReg", question: "ReQuest total registered teachers", type: "number" },
      // { id: "noRequestRegTarget", question: "Target", type: "number" },
      { id: "requestComment", question: "Any Comments", type: "", inType: 'textarea' },
      // { id: "noYouthEvents", question: "No. Youth events (org wide)", type: "number" },
      // { id: "noYoungReach", question: "No. Young people reached (org wide)", type: "number" },
      // { id: "noYoungOpport", question: "No. Young people given an opportunity to become a follower of Jesus (org wide)", type: "number" },
      // { id: "noYoungFollow", question: "No. Young people made a decision to follow Jesus (org wide)", type: "number" },
      { id: "noYouthLeader", question: "No. Youth Leader events (org wide)", type: "number" },
      { id: "noYouthLeaderEngage", question: "No. Youth Leaders engaged with (org wide)", type: "number" },
      // { id: "youthComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noArticles", question: "No. of articles written and published in external publications (org wide)", type: "number" },
      { id: "noPodcasts", question: "No. of podcasts recorded and uploaded (org wide)", type: "number" },
      { id: "noTrainVideo", question: "No. Training Wall videos", type: "number" },
      { id: "mediaComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noSpeakEvent", question: "No of speaking at events (org wide)", type: "number" },
      { id: "speakEventComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "manageAccounts", question: "Management accounts for Innovation Surplus/Deficit £", type: "number" },
      { id: "accountComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "comments", question: "Comments", inType: 'textarea', type: "number" },
      { id: "staffUpdates", question: "Staffing Updates", inType: 'textarea', type: "number" },
    ]
  },
  {
    id: "Spiritual",
    sections: [
          { id: "noPrayerPartner", question: "No. Prayer partners (contrast against target)", type: "number" },
          // { id: "noPrayerPartnerTarget", question: "Target", type: "number" },
          { id: "noPrayerInitiative", question: "No. Prayer initiatives (org wide)", type: "number" },
          { id: "prayerComment", question: "Any Comments", type: "", inType: 'textarea' },
          { id: "noCentreEngage", question: "No. Centres engaged in research", type: "number" },
          { id: "researchProcess", question: "Centre research process (begun for the month)", type: "number" },
          { id: "researchLaunch", question: "Centre research launched (complete that month)", type: "number" },
          { id: "centreComment", question: "Any Comments", type: "", inType: 'textarea' },
          { id: "interResearchProcess", question: "International research process (begun for the month)", type: "number" },
          { id: "interResearchLaunch", question: "International research launched (complete for the month)", type: "number" },
          { id: "interContact", question: "International contact support for nations (nations given any kind of assistance)", type: "number" },
          { id: "resourceAvail", question: "Resources made available to nations (for the month)", type: "number" },
          { id: "resourceEnquiry", question: "Resources enquiry for International (for the month)", type: "number" },
          { id: "resourceInterProcess", question: "Resources for International process (begun for the month)", type: "number" },
          { id: "resourceInterLaunch", question: "Resources for International launched (complete for the month)", type: "number" },
          { id: "researchComment", question: "Any Comments", type: "", inType: 'textarea' },
          // { id: "noYouthEvents", question: "No. Youth events (org wide)", type: "number" },
          // { id: "noYoungReach", question: "No. Young people reached (org wide)", type: "number" },
          // { id: "noYoungOpport", question: "No. Young people given an opportunity to become a follower of Jesus (org wide)", type: "number" },
          // { id: "noYoungFollow", question: "No. Young people made a decision to follow Jesus (org wide)", type: "number" },
          { id: "noYouthLeader", question: "No. Youth Leader events (org wide)", type: "number" },
          { id: "noYouthLeaderEngage", question: "No. Youth Leaders engaged with (org wide)", type: "number" },
          // { id: "youthComment", question: "Any Comments", type: "", inType: 'textarea' },
          { id: "noArticles", question: "No. of articles written and published in external publications (org wide)", type: "number" },
          { id: "noPodcasts", question: "No. of podcasts recorded and uploaded (org wide)", type: "number" },
          { id: "mediaComment", question: "Any Comments", type: "", inType: 'textarea' },
          { id: "noSpeakEvent", question: "No of speaking at events (org wide)", type: "number" },
          { id: "speakEventComment", question: "Any Comments", type: "", inType: 'textarea' },
          { id: "manageAccounts", question: "Management accounts for Spiritual Surplus/Deficit £", type: "number" },
          { id: "accountComment", question: "Any Comments", type: "", inType: 'textarea' },
          { id: "comments", question: "Comments", inType: 'textarea', type: "number" },
          { id: "staffUpdates", question: "Staffing Updates", inType: 'textarea', type: "number" },
    ]
  },
  {
    id: "Together",
    sections: [
      { id: "noGrantWritten", question: "No. Grants written & submitted (contrast against target)", type: "number" },
      // { id: "noGrantWrittenTarget", question: "Target", type: "number" },
      { id: "noGrantSubmit", question: "No. Grant reports submitted", type: "number" },
      { id: "noGrantSuccess", question: "No. Grants Successful", type: "number" },
      { id: "noGrantReceived", question: "No. Grants actually received", type: "number" },
      { id: "grandComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noTFTDsub", question: "No. TFTD subscribers (contrast against target)", type: "number" },
      // { id: "noTFTDsubTarget", question: "Target", type: "number" },
      { type: "Empty" },
      { id: "noTFTDopen", question: "No. TFTD open rate (show last 3 months)", type: "number" },
      { id: "TFTDComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noPartners", question: "No. Partners (show last 3 months)", type: "number" },
      { id: "noNewPartners", question: "No. New partners (contrast against target)", type: "number" },
      // { id: "noNewPartnersTarget", question: "Target", type: "number" },
      { id: "noNewSupporter", question: "No. New personal supporters (contrast against target)", type: "number" },
      // { id: "noNewSupporterTarget", question: "Target", type: "number" },
      { id: "partnerComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noEvents", question: "No. Events (stands/fundraisers/church invitations) (show last 3 months)", type: "number" },
      { id: "noDonors", question: "No. Major donors contacted (contrast against target)", type: "number" },
      // { id: "noDonorsTarget", question: "Target", type: "number" },
      { id: "noDonorsVisit", question: "No. Major donors visited (contrast against target)", type: "number" },
      // { id: "noDonorsVisitTarget", question: "Target", type: "number" },
      { id: "noDonorsGiven", question: "No. Major donors given (contrast against target)", type: "number" },
      // { id: "noDonorsGivenTarget", question: "Target", type: "number" },
      { id: "donorComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noSubscriber", question: "No. subscribers (show last 3 months)", type: "number" },
      { id: "noNewSubscriber", question: "No. New Resource subscribers (contrast against target)", type: "number" },
      // { id: "noNewSubscriberTarget", question: "Target", type: "number" },
      { id: "subscriberComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noTotalRegister", question: "No. Total registered MyLife licences", type: "number" },
      { id: "noCourseSale", question: "No. MyLife licenses (contrast against target)", type: "number" },
      // { id: "noCourseSaleTarget", question: "Target", type: "number" },
      { id: "noBooksSale", question: "No. of any MyLife printed material sold (contrast against target).", type: "number" },
      // { id: "noBooksSaleTarget", question: "Target", type: "number" },
      { id: "noAccredEntries", question: "No. MyLife accreditation entries", type: "number" },
      { id: "noAppointment", question: "No. of new MyLife opportunities/appointments this month (contrast against target)", type: "number" },
      { id: "noTrainSession", question: "No. of training sessions held", type: "number" },
      { id: "noPeopleTrain", question: "No. of people trained", type: "number" },
      // { id: "noAppointmentTarget", question: "Target", type: "number" },
      { id: "salesComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noSeriesSent", question: "No. Why Series sent out (contrast against target)", type: "number" },
      // { id: "noSeriesSentTarget", question: "Target", type: "number" },
      { id: "seriesComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noSocialPost", question: "No. Social media posts (contrast against target)", type: "number" },
      // { id: "noSocialPostTarget", question: "Target", type: "number" },
      { id: "postComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noPaidAd", question: "No. Paid advertising adverts (contrast against target)", type: "number" },
      // { id: "noPaidAdTarget", question: "Target", type: "number" },
      { id: "adsComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noWebsiteHit", question: "Website hits (unique users) (show last 3 months)", type: "number" },
      { id: "noWebsiteClick", question: "Website multiple clicks (show last 3 months)", type: "number" },
      { id: "websiteComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "stackRate", question: "Stack open rate", type: "number" },
      { id: "stackComment", question: "Any Comments", type: "", inType: 'textarea' },
      // { id: "noYouthEvents", question: "No. Youth events (org wide)", type: "number" },
      // { id: "noYoungReach", question: "No. Young people reached (org wide)", type: "number" },
      // { id: "noYoungOpport", question: "No. Young people given an opportunity to become a follower of Jesus (org wide)", type: "number" },
      // { id: "noYoungFollow", question: "No. Young people made a decision to follow Jesus (org wide)", type: "number" },
      { id: "noYouthLeader", question: "No. Youth Leader events (org wide)", type: "number" },
      { id: "noYouthLeaderEngage", question: "No. Youth Leaders engaged with (org wide)", type: "number" },
      // { id: "youthComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noArticles", question: "No. of articles written and published in external publications (org wide)", type: "number" },
      { id: "noPodcasts", question: "No. of podcasts recorded and uploaded (org wide)", type: "number" },
      { id: "mediaComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "noSpeakEvent", question: "No of speaking at events (org wide)", type: "number" },
      { id: "speakEventComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "manageAccounts", question: "Management accounts for Together Surplus/Deficit £", type: "number" },
      { id: "accountComment", question: "Any Comments", type: "", inType: 'textarea' },
      { id: "comments", question: "Comments", inType: 'textarea', type: "number" },
      { id: "staffUpdates", question: "Staffing Updates", inType: 'textarea', type: "number" },
    ]
  },
]