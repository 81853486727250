import { gql } from "@apollo/client";

export const ON_LOGIN = gql`
mutation OnAdminLogin($login: LoginInput) {
  onAdminLogin(login: $login)
}`

export const CREATE_USER = gql`
mutation CreateUser($user: UserInput) {
  createUser(user: $user)
}`

export const UPDATE_USER = gql`
mutation UpdateUser($user: UserInput) {
  updateUser(user: $user)
}`

export const CREATE_TARGET = gql`
mutation CreateTarget($target: TargetInput) {
  createTarget(target: $target)
}`

export const UPDATE_TARGET = gql`
mutation UpdateTarget($target: TargetInput, $updateTargetId: ID) {
  updateTarget(target: $target, id: $updateTargetId)
}`

export const DELETE_USER = gql`
mutation DeleteUser($deleteUserId: ID!) {
  deleteUser(id: $deleteUserId)
}`

export const UPDATE_STAFF_REPORT = gql`
mutation UpdateStaffReport($updateStaffReportId: ID, $report: StaffReportInput) {
  updateStaffReport(id: $updateStaffReportId, report: $report)
}`

export const DELETE_STAFF_REPORT = gql`
mutation DeleteStaffReport($deleteStaffReportId: String) {
  deleteStaffReport(id: $deleteStaffReportId)
}`

export const UPDATE_REPORT = gql`
mutation UpdateReport($updateReportId: ID, $report: ReportInput) {
  updateReport(id: $updateReportId, report: $report)
}`