'use client'
import React, { useRef, useState } from 'react'
import './style.css'
import Header from '../layout/header'
import { useQuery } from '@apollo/client'
import { GET_MONTH_REPORT } from '../graphql/query'
import Chart from 'react-apexcharts'
import html2pdf from 'html2pdf.js';

export default function Report() {
    const date = new Date()
    const [type, setType] = useState("")
    const [month, setMonth] = useState(date?.toLocaleString('default', { month: 'short' }))
    const [months, setMonths] = useState(date?.toLocaleString('default', { month: 'long' }))
    const year = date?.getFullYear();

    const selectedMonth = monthNames?.findIndex((e) => e === months)
    function getMonthByIndex(index) {
        const adjustedIndex = (index % monthNames.length + monthNames.length) % monthNames.length;
        return monthNames[adjustedIndex];
    }
    
    const oldMonths = [getMonthByIndex(selectedMonth - 2) + `-${year}`, getMonthByIndex(selectedMonth - 1) + `-${year}`, getMonthByIndex(selectedMonth) + `-${year}`]
    
    const { data, loading } = useQuery(GET_MONTH_REPORT, {
        variables: {
            "months": oldMonths
        }
    })

    const refs = useRef(null)

    const onPrint = async () => {
        if (refs?.current) {
            await html2pdf(refs?.current, { image: { type: 'jpeg', quality: 1 }, filename: `Top Level Report.pdf`, jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }, pagebreak: { mode: ['avoid-all', 'css', 'legacy'] } });
        }
    }

    return (
        <div className='Answer'>
            <Header title={'Reports'} month={month} setMonth={setMonth} type={type} setType={setType} btn={<button onClick={()=>onPrint()}>Download</button>}/>
            <span>
                {loading ?
                    <div className="Loading">
                        Loading...
                    </div>
                    :
                    <div ref={refs} className='Answer_Con'>
                        {reportDatas?.filter((e)=> type? e?.id == type : true)?.map((item, index) => {
                            const getAns = (id, report) => {
                                const dat = report ? JSON.parse(report) : null
                                return report ? dat[id] : null
                            }
                            const datas = data?.getMonthReports?.filter((e)=>e?.role === item?.id)
                            const target = data?.getTargets?.find((e)=> e?.role === item?.id)?.targets ? JSON.parse(data?.getTargets?.find((e)=> e?.role === item?.id)?.targets) : null
                            return (
                                <div className='Answer_Con1' key={index}>
                                    <h2>{item?.id}</h2>
                                    <div className='Report_Graph_Con'>
                                        {item?.sections?.map((itm, ind) => {
                                            return (
                                                <div key={ind} className='Report_Graph'>
                                                    <p>{itm?.question}</p>
                                                    <Charts item={itm} targets={target} oldMonths={oldMonths} datas={datas}/>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                }
            </span>
        </div>
    )
}

function Charts({datas, oldMonths, item, targets}){
    const target = targets ? targets[item?.id] || "" : ""
    var options = {
        chart: {
            legend: {
                show: false
            },
            type: 'bar',
            xaxis: {
                categories: oldMonths
            },
            chart: {
                toolbar: {
                    show: false
                }
            },
            stroke: {
                width: 2
            },
            tooltip: {
                custom: function({ series, seriesIndex, dataPointIndex, w }) {
                    return (
                      `<div class="ToolTip">
                      ${w.globals.seriesNames[seriesIndex]}: ${series[seriesIndex][dataPointIndex]}
                      </div>`
                    )
                  }
            },
        },
        series: [{
            name: 'Answer',
            type: 'column',
            data: oldMonths.map((itm) => {
                const data = datas?.find((e)=>e?.month === itm)?.reports? JSON.parse(datas?.find((e)=>e?.month === itm)?.reports) : {}
                return data[item?.id] || 0
            })
            },
            {
                name: 'Yearly Target',
                type: 'line',
                data: oldMonths?.map((itm) => target || 0)
            },
        ],
        series1: [{
            name: 'Answer',
            type: 'column',
            data: oldMonths.map((itm) => {
                const data = datas?.find((e)=>e?.month === itm)?.reports? JSON.parse(datas?.find((e)=>e?.month === itm)?.reports) : {}
                return data[item?.id] || 0
            })
        }],
    }
    return <Chart options={options.chart} type={'line'} series={target ? options.series : options?.series1} />
}

// const reportDatas = [
//     {
//         id: "Helpful",
//         sections: [
//             { id: "incomeLast", question: "Income (last complete month)", type: "number" },
//             { id: "incomeBudget", question: "Income against budget", type: "number" },
//             { id: "incomeForecast", question: "Income against forecast", type: "number" },
//             { id: "expenseLast", question: "Expense (last complete month)", type: "number" },
//             { id: "expenseBudget", question: "Expense against budget", type: "number" },
//             { id: "expenseForecast", question: "Expense against forecast", type: "number" },
//             { id: "generalCash", question: "General & Designated Cash £", type: "number" },
//             { id: "generalRestrict", question: "Restricted Cash £", type: "number" },
//             { id: "noCourStaffSick", question: "Number of staff sick (Courageous)", type: "number" },
//             { id: "noCourSickDays", question: "Number of sick days taken (Courageous)", type: "number" },
//             { id: "noCourStaffAL", question: "Number of staff on AL (Courageous)", type: "number" },
//             { id: "noCourALDays", question: "Number of AL days taken (Courageous)", type: "number" },
//             { id: "noHelpStaffSick", question: "Number of staff sick (Helpful)", type: "number" },
//             { id: "noHelpSickDays", question: "Number of sick days taken (Helpful)", type: "number" },
//             { id: "noHelpStaffAL", question: "Number of staff on AL (Helpful)", type: "number" },
//             { id: "noHelpALDays", question: "Number of AL days taken (Helpful)", type: "number" },
//             { id: "noRelStaffSick", question: "Number of staff sick (Relational)", type: "number" },
//             { id: "noRelSickDays", question: "Number of sick days taken (Relational)", type: "number" },
//             { id: "noRelStaffAL", question: "Number of staff on AL (Relational)", type: "number" },
//             { id: "noRelALDays", question: "Number of AL days taken (Relational)", type: "number" },
//             { id: "noInovStaffSick", question: "Number of staff sick (Innovation)", type: "number" },
//             { id: "noInovSickDays", question: "Number of sick days taken (Innovation)", type: "number" },
//             { id: "noInovStaffAL", question: "Number of staff on AL (Innovation)", type: "number" },
//             { id: "noInovALDays", question: "Number of AL days taken (Innovation)", type: "number" },
//             { id: "noSprStaffSick", question: "Number of staff sick (Spiritual)", type: "number" },
//             { id: "noSprSickDays", question: "Number of sick days taken (Spiritual)", type: "number" },
//             { id: "noSprStaffAL", question: "Number of staff on AL (Spiritual)", type: "number" },
//             { id: "noSprALDays", question: "Number of AL days taken (Spiritual)", type: "number" },
//             { id: "noTogStaffSick", question: "Number of staff sick (Together)", type: "number" },
//             { id: "noTogSickDays", question: "Number of sick days taken (Together)", type: "number" },
//             { id: "noTogStaffAL", question: "Number of staff on AL (Together)", type: "number" },
//             { id: "noTogALDays", question: "Number of AL days taken (Together)", type: "number" },
//             { id: "noResignation", question: "No. Resignations", type: "number" },
//             { id: "noNewStarter", question: "No. New starters", type: "number" },
//             { id: "noRecruit", question: "No. Recruiting for posts", type: "number" },
//             { id: "noTrainEvents", question: "No. Training events (contrast against target)", type: "number" },
//             { id: "noOnlineSales", question: "No. Online shop sales", type: "number" },
//             { id: "noYouthEvents", question: "No. Youth events (org wide)", type: "number" },
//             { id: "noYoungReach", question: "No. Young people reached (org wide)", type: "number" },
//             { id: "noYoungOpport", question: "No. Young people given an opportunity to become a follower of Jesus (org wide)", type: "number" },
//             { id: "noYoungFollow", question: "No. Young people made a decision to follow Jesus (org wide)", type: "number" },
//             { id: "noYouthLeader", question: "No. Youth Leader events (org wide)", type: "number" },
//             { id: "noYouthLeaderEngage", question: "No. Youth Leaders engaged with (org wide)", type: "number" },
//             { id: "noArticles", question: "No. of articles written and published in external publications (org wide)", type: "number" },
//             { id: "noPodcasts", question: "No. of podcasts recorded and uploaded (org wide)", type: "number" },
//             { id: "noSpeakEvent", question: "No of speaking at events (org wide)", type: "number" },
//             { id: "manageAccounts", question: "Management accounts for Helpful surplus/deficit £", type: "number" },
//         ]
//     },
//     {
//         id: "Together",
//         sections: [
//             { id: "noGrantWritten", question: "No. Grants written & submitted (contrast against target)", type: "number" },
//             { id: "noGrantSubmit", question: "No. Grant reports submitted", type: "number" },
//             { id: "noGrantSuccess", question: "No. Grants Successful", type: "number" },
//             { id: "noGrantReceived", question: "No. Grants actually received", type: "number" },
//             { id: "noTFTDsub", question: "No. TFTD subscribers (contrast against target)", type: "number" },
//             { id: "noTFTDopen", question: "No. TFTD open rate (show last 3 months)", type: "number" },
//             { id: "noPartners", question: "No. Partners (show last 3 months)", type: "number" },
//             { id: "noNewPartners", question: "No. New partners (contrast against target)", type: "number" },
//             { id: "noNewSupporter", question: "No. New personal supporters (contrast against target)", type: "number" },
//             { id: "noEvents", question: "No. Events (stands/fundraisers/church invitations) (show last 3 months)", type: "number" },
//             { id: "noDonors", question: "No. Major donors contacted (contrast against target)", type: "number" },
//             { id: "noDonorsVisit", question: "No. Major donors visited (contrast against target)", type: "number" },
//             { id: "noDonorsGiven", question: "No. Major donors given (contrast against target)", type: "number" },
//             { id: "noSubscriber", question: "No. subscribers (show last 3 months)", type: "number" },
//             { id: "noNewSubscriber", question: "No. New subscribers (contrast against target)", type: "number" },
//             { id: "noTotalRegister", question: "No. Total registered MyLife licences", type: "number" },
//             { id: "noCourseSale", question: "No. MyLife Course sales (contrast against target)", type: "number" },
//             { id: "noBooksSale", question: "No. of any MyLife printed material sold (contrast against target).", type: "number" },
//             { id: "noAccredEntries", question: "No. MyLife accreditation entries", type: "number" },
//             { id: "noAppointment", question: "No. of new opportunities/appointments this month (contrast against target)", type: "number" },
//             { id: "noSeriesSent", question: "No. Why Series sent out (contrast against target)", type: "number" },
//             { id: "noSocialPost", question: "No. Social media posts (contrast against target)", type: "number" },
//             { id: "noPaidAd", question: "No. Paid advertising adverts (contrast against target)", type: "number" },
//             { id: "noWebsiteHit", question: "Website hits (unique users) (show last 3 months)", type: "number" },
//             { id: "noWebsiteClick", question: "Website multiple clicks (show last 3 months)", type: "number" },
//             { id: "stackRate", question: "Stack open rate", type: "number" },
//             { id: "noYouthEvents", question: "No. Youth events (org wide)", type: "number" },
//             { id: "noYoungReach", question: "No. Young people reached (org wide)", type: "number" },
//             { id: "noYoungOpport", question: "No. Young people given an opportunity to become a follower of Jesus (org wide)", type: "number" },
//             { id: "noYoungFollow", question: "No. Young people made a decision to follow Jesus (org wide)", type: "number" },
//             { id: "noYouthLeader", question: "No. Youth Leader events (org wide)", type: "number" },
//             { id: "noYouthLeaderEngage", question: "No. Youth Leaders engaged with (org wide)", type: "number" },
//             { id: "noArticles", question: "No. of articles written and published in external publications (org wide)", type: "number" },
//             { id: "noPodcasts", question: "No. of podcasts recorded and uploaded (org wide)", type: "number" },
//             { id: "noSpeakEvent", question: "No of speaking at events (org wide)", type: "number" },
//             { id: "manageAccounts", question: "Management accounts for Together Surplus/Deficit £", type: "number" },
//         ]
//     },
//     {
//         id: "Innovation",
//         sections: [
//             { id: "noProjectDesign", question: "No. Projects assigned Design (show last 3 months)", type: "number" },
//             { id: "noCompleteDesign", question: "No. Projects completed Design (show last 3 months)", type: "number" },
//             { id: "noProjectVideo", question: "No. Projects assigned Video (show last 3 months)", type: "number" },
//             { id: "noCompleteVideo", question: "No. Projects completed Video (show last 3 months)", type: "number" },
//             { id: "noSchoolContent", question: "No. School lessons/Assembly content produced (contrast against target)", type: "number" },
//             { id: "noCommunity", question: "No. Community content produced (contrast against target)", type: "number" },
//             { id: "noChurchContent", question: "No. Church content produced (contrast against target)", type: "number" },
//             { id: "noUniqueProject", question: "No. Stand alone / Unique projects produced (show last 3 months)", type: "number" },
//             { id: "noRequestHit", question: "ReQuest hits (unique users) (show last 3 months)", type: "number" },
//             { id: "noRequestReg", question: "ReQuest no. Registered teachers (contrast against target) ", type: "number" },
//             { id: "noYouthEvents", question: "No. Youth events (org wide)", type: "number" },
//             { id: "noYoungReach", question: "No. Young people reached (org wide)", type: "number" },
//             { id: "noYoungOpport", question: "No. Young people given an opportunity to become a follower of Jesus (org wide)", type: "number" },
//             { id: "noYoungFollow", question: "No. Young people made a decision to follow Jesus (org wide)", type: "number" },
//             { id: "noYouthLeader", question: "No. Youth Leader events (org wide)", type: "number" },
//             { id: "noYouthLeaderEngage", question: "No. Youth Leaders engaged with (org wide)", type: "number" },
//             { id: "noArticles", question: "No. of articles written and published in external publications (org wide)", type: "number" },
//             { id: "noPodcasts", question: "No. of podcasts recorded and uploaded (org wide)", type: "number" },
//             { id: "noTrainVideo", question: "No. Training Wall videos ", type: "number" },
//             { id: "noSpeakEvent", question: "No of speaking at events (org wide)", type: "number" },
//             { id: "manageAccounts", question: "Management accounts for Innovation Surplus/Deficit £", type: "number" },
//         ]

//     },
//     {
//         id: "Relational",
//         sections: [
//             { id: "noCentreNew", question: "No. new centres (contrast against target)", type: "number" },
//             { id: "noNewCentre", question: "No. New Centre enquiries", type: "number" },
//             { id: "noCentreContact", question: "No. Centre contacts (contrast against target)", type: "number" },
//             { id: "noCentreVisits", question: "No. Centre visits (contrast against target)", type: "number" },
//             { id: "noCentreTrustee", question: "No. Centre Trustee contacts/visits (contrast against target)", type: "number" },
//             { id: "noChurchEnquiry", question: "No. Church enquiries", type: "number" },
//             { id: "noChurchVisit", question: "No. Church visits (contrast against target)", type: "number" },
//             { id: "noChurchFollowUp", question: "No. Church follow up visits (contrast against target)", type: "number" },
//             { id: "noPluseEnquiry", question: "No. Pulse enquiries", type: "number" },
//             { id: "noPluseGather", question: "No. Pulse Gatherings - Total (contrast against target)", type: "number" },
//             { id: "noPluseLearn", question: "No. Pulse Learning Communities - Total (contrast against target)", type: "number" },
//             { id: "noColdCall", question: "No. Cold call follow ups", type: "number" },
//             { id: "noUrbanEvent", question: "No. Urban Outreach events (contrast against target)", type: "number" },
//             { id: "noUrbanYouthEvent", question: "No. Urban youth leader events (contrast against target)", type: "number" },
//             { id: "noJonJacketHit", question: "No. Jon Jackel online hits", type: "number" },
//             { id: "noJonJacketSale", question: "No. Jon Jackel Merch sales (units) ", type: "number" },
//             { id: "noYouthEvents", question: "No. Youth events (org wide)", type: "number" },
//             { id: "noYoungReach", question: "No. Young people reached (org wide)", type: "number" },
//             { id: "noYoungOpport", question: "No. Young people given an opportunity to become a follower of Jesus (org wide)", type: "number" },
//             { id: "noYoungFollow", question: "No. Young people made a decision to follow Jesus (org wide)", type: "number" },
//             { id: "noYouthLeader", question: "No. Youth Leader events (org wide)", type: "number" },
//             { id: "noYouthLeaderEngage", question: "No. Youth Leaders engaged with (org wide)", type: "number" },
//             { id: "noArticles", question: "No. of articles written and published in external publications (org wide)", type: "number" },
//             { id: "noPodcasts", question: "No. of podcasts recorded and uploaded (org wide)", type: "number" },
//             { id: "noSpeakEvent", question: "No of speaking at events (org wide)", type: "number" },
//             { id: "manageAccounts", question: "Management accounts for Relational Surplus/Deficit £", type: "number" },
//         ]
//     },
//     {
//         id: "Courageous",
//         sections: [
//             { id: "noSocialResponse", question: "No. Advocacy social media responses (contrast against target)", type: "number" },
//             { id: "noPressEngage", question: "No. Advocacy Press engagements (contrast against target)", type: "number" },
//             { id: "noChurchEngage", question: "No. Denominational / church advocacy engagements (contrast against target)", type: "number" },
//             { id: "noNewProject", question: "No. New Projects initiated (show last 3 months)", type: "number" },
//             { id: "noYouthEvents", question: "No. Youth events (org wide)", type: "number", auto: true },
//             { id: "noYoungReach", question: "No. Young people reached (org wide)", type: "number", auto: true },
//             { id: "noYoungOpport", question: "No. Young people given an opportunity to become a follower of Jesus (org wide)", type: "number", auto: true },
//             { id: "noYoungFollow", question: "No. Young people made a decision to follow Jesus (org wide)", type: "number", auto: true },
//             { id: "noYouthLeader", question: "No. Youth Leader events (org wide)", type: "number", auto: true },
//             { id: "noYouthLeaderEngage", question: "No. Youth Leaders engaged with (org wide)", type: "number", auto: true },
//             { id: "noArticles", question: "No. of articles written and published in external publications (org wide)", type: "number", auto: true },
//             { id: "noPodcasts", question: "No. of podcasts recorded and uploaded (org wide)", type: "number", auto: true },
//             { id: "noNewPartner", question: "No. New partnerships (org wide)", type: "number" },
//             { id: "noExternal", question: "No. External appointments", type: "number" },
//             { id: "noStrategic", question: "No. of strategic bodies (contrast against target)", type: "number" },
//             { id: "noSpeakEvent", question: "No of speaking at events", type: "number" },
//             { id: "manageAccounts", question: "Management accounts for Courageous Surplus/Deficit £", type: "number" },
//         ]
//     },
//     {
//         id: "Spiritual",
//         sections: [
//             { id: "noPrayerPartner", question: "No. Prayer partners (contrast against target)", type: "number" },
//             { id: "noPrayerInitiative", question: "No. Prayer initiatives (org wide)", type: "number" },
//             { id: "noCentreEngage", question: "No. Centres engaged in research", type: "number" },
//             { id: "researchProcess", question: "Centre research process (begun for the month)", type: "number" },
//             { id: "researchLaunch", question: "Centre research launched (complete that month)", type: "number" },
//             { id: "interResearchProcess", question: "International research process (begun for the month)", type: "number" },
//             { id: "interResearchLaunch", question: "International research launched (complete for the month)", type: "number" },
//             { id: "interContact", question: "International contact support for nations (nations given any kind of assistance)", type: "number" },
//             { id: "resourceAvail", question: "Resources made available to nations (for the month)", type: "number" },
//             { id: "resourceEnquiry", question: "Resources enquiry for International (for the month)", type: "number" },
//             { id: "resourceInterProcess", question: "Resources for International process (begun for the month)", type: "number" },
//             { id: "resourceInterLaunch", question: "Resources for International launched (complete for the month)", type: "number" },
//             { id: "noYouthEvents", question: "No. Youth events (org wide)", type: "number" },
//             { id: "noYoungReach", question: "No. Young people reached (org wide)", type: "number" },
//             { id: "noYoungOpport", question: "No. Young people given an opportunity to become a follower of Jesus (org wide)", type: "number" },
//             { id: "noYoungFollow", question: "No. Young people made a decision to follow Jesus (org wide)", type: "number" },
//             { id: "noYouthLeader", question: "No. Youth Leader events (org wide)", type: "number" },
//             { id: "noYouthLeaderEngage", question: "No. Youth Leaders engaged with (org wide)", type: "number" },
//             { id: "noArticles", question: "No. of articles written and published in external publications (org wide)", type: "number" },
//             { id: "noPodcasts", question: "No. of podcasts recorded and uploaded (org wide)", type: "number" },
//             { id: "noSpeakEvent", question: "No of speaking at events (org wide)", type: "number" },
//             { id: "manageAccounts", question: "Management accounts for Spiritual Surplus/Deficit £", type: "number" },
//         ]
//     },
// ]

const reportDatas = [
    {
        id: "Helpful",
        sections: [
            { id: "incomeLast", question: "Income (last complete month) (The income total reflects the position at the close of the latest management accounts)", type: "number" },
            { id: "incomeBudget", question: "Income against budget", type: "number" },
            // { id: "incomeForecast", question: "Income against forecast", type: "number" },
            { id: "expenseLast", question: "Expense (last complete month) (The expenditure total reflects the position at the close of the latest management accounts)", type: "number" },
            { id: "expenseBudget", question: "Expense against budget", type: "number" },
            // { id: "expenseForecast", question: "Expense against forecast", type: "number" },
            { id: "generalCash", question: "General & Designated Cash £ (Month end position)", type: "number" },
            { id: "generalRestrict", question: "Restricted Cash £ (Month end position)", type: "number" },
            { id: "noCourStaffSick", question: "Number of staff sick (Courageous)", type: "number" },
            { id: "noCourSickDays", question: "Number of sick days taken (Courageous)", type: "number" },
            { id: "noCourStaffAL", question: "Number of staff on AL (Courageous)", type: "number" },
            { id: "noCourALDays", question: "Number of AL days taken (Courageous)", type: "number" },
            { id: "noHelpStaffSick", question: "Number of staff sick (Helpful)", type: "number" },
            { id: "noHelpSickDays", question: "Number of sick days taken (Helpful)", type: "number" },
            { id: "noHelpStaffAL", question: "Number of staff on AL (Helpful)", type: "number" },
            { id: "noHelpALDays", question: "Number of AL days taken (Helpful)", type: "number" },
            { id: "noRelStaffSick", question: "Number of staff sick (Relational)", type: "number" },
            { id: "noRelSickDays", question: "Number of sick days taken (Relational)", type: "number" },
            { id: "noRelStaffAL", question: "Number of staff on AL (Relational)", type: "number" },
            { id: "noRelALDays", question: "Number of AL days taken (Relational)", type: "number" },
            { id: "noInovStaffSick", question: "Number of staff sick (Innovation)", type: "number" },
            { id: "noInovSickDays", question: "Number of sick days taken (Innovation)", type: "number" },
            { id: "noInovStaffAL", question: "Number of staff on AL (Innovation)", type: "number" },
            { id: "noInovALDays", question: "Number of AL days taken (Innovation)", type: "number" },
            { id: "noSprStaffSick", question: "Number of staff sick (Spiritual)", type: "number" },
            { id: "noSprSickDays", question: "Number of sick days taken (Spiritual)", type: "number" },
            { id: "noSprStaffAL", question: "Number of staff on AL (Spiritual)", type: "number" },
            { id: "noSprALDays", question: "Number of AL days taken (Spiritual)", type: "number" },
            { id: "noTogStaffSick", question: "Number of staff sick (Together)", type: "number" },
            { id: "noTogSickDays", question: "Number of sick days taken (Together)", type: "number" },
            { id: "noTogStaffAL", question: "Number of staff on AL (Together)", type: "number" },
            { id: "noTogALDays", question: "Number of AL days taken (Together)", type: "number" },
            { id: "noResignation", question: "No. Resignations", type: "number" },
            { id: "noNewStarter", question: "No. New starters", type: "number" },
            { id: "noRecruit", question: "No. Recruiting for posts", type: "number" },
            { id: "noTrainEvents", question: "No. Training events (contrast against target)", type: "number" },
            { id: "noOnlineSales", question: "No. Online shop orders", type: "number" },
            { id: "noProductOnlineSales", question: "No. Of products sold from online shop orders", type: "number" },
            // { id: "noYouthEvents", question: "No. Youth events (org wide)", type: "number" },
            // { id: "noYoungReach", question: "No. Young people reached (org wide)", type: "number" },
            // { id: "noYoungOpport", question: "No. Young people given an opportunity to become a follower of Jesus (org wide)", type: "number" },
            // { id: "noYoungFollow", question: "No. Young people made a decision to follow Jesus (org wide)", type: "number" },
            { id: "noYouthLeader", question: "No. Youth Leader events (org wide)", type: "number" },
            { id: "noYouthLeaderEngage", question: "No. Youth Leaders engaged with (org wide)", type: "number" },
            { id: "noArticles", question: "No. of articles written and published in external publications (org wide)", type: "number" },
            { id: "noPodcasts", question: "No. of podcasts recorded and uploaded (org wide)", type: "number" },
            { id: "noSpeakEvent", question: "No of speaking at events (org wide)", type: "number" },
            { id: "manageAccounts", question: "Management accounts for Helpful surplus/deficit £ (This total reflects the position at the close of the latest management accounts)", type: "number" },
        ]
    },
    {
        id: "Together",
        sections: [
            { id: "noGrantWritten", question: "No. Grants written & submitted (contrast against target)", type: "number" },
            { id: "noGrantSubmit", question: "No. Grant reports submitted", type: "number" },
            { id: "noGrantSuccess", question: "No. Grants Successful", type: "number" },
            { id: "noGrantReceived", question: "No. Grants actually received", type: "number" },
            { id: "noTFTDsub", question: "No. TFTD subscribers (contrast against target)", type: "number" },
            { id: "noTFTDopen", question: "No. TFTD open rate (show last 3 months)", type: "number" },
            { id: "noPartners", question: "No. Partners (show last 3 months)", type: "number" },
            { id: "noNewPartners", question: "No. New partners (contrast against target)", type: "number" },
            { id: "noNewSupporter", question: "No. New personal supporters (contrast against target)", type: "number" },
            { id: "noEvents", question: "No. Events (stands/fundraisers/church invitations) (show last 3 months)", type: "number" },
            { id: "noDonors", question: "No. Major donors contacted (contrast against target)", type: "number" },
            { id: "noDonorsVisit", question: "No. Major donors visited (contrast against target)", type: "number" },
            { id: "noDonorsGiven", question: "No. Major donors given (contrast against target)", type: "number" },
            { id: "noSubscriber", question: "No. subscribers (show last 3 months)", type: "number" },
            { id: "noNewSubscriber", question: "No. New Resource subscribers (contrast against target)", type: "number" },
            { id: "noTotalRegister", question: "No. Total registered MyLife licences", type: "number" },
            { id: "noCourseSale", question: "No. MyLife licenses (contrast against target)", type: "number" },
            { id: "noBooksSale", question: "No. of any MyLife printed material sold (contrast against target).", type: "number" },
            { id: "noAccredEntries", question: "No. MyLife accreditation entries", type: "number" },
            { id: "noAppointment", question: "No. of new MyLife opportunities/appointments this month (contrast against target)", type: "number" },
            { id: "noTrainSession", question: "No. of training sessions held", type: "number" },
            { id: "noPeopleTrain", question: "No. of people trained", type: "number" },
            { id: "noSeriesSent", question: "No. Why Series sent out (contrast against target)", type: "number" },
            { id: "noSocialPost", question: "No. Social media posts (contrast against target)", type: "number" },
            { id: "noPaidAd", question: "No. Paid advertising adverts (contrast against target)", type: "number" },
            { id: "noWebsiteHit", question: "Website hits (unique users) (show last 3 months)", type: "number" },
            { id: "noWebsiteClick", question: "Website multiple clicks (show last 3 months)", type: "number" },
            { id: "stackRate", question: "Stack open rate", type: "number" },
            // { id: "noYouthEvents", question: "No. Youth events (org wide)", type: "number" },
            // { id: "noYoungReach", question: "No. Young people reached (org wide)", type: "number" },
            // { id: "noYoungOpport", question: "No. Young people given an opportunity to become a follower of Jesus (org wide)", type: "number" },
            // { id: "noYoungFollow", question: "No. Young people made a decision to follow Jesus (org wide)", type: "number" }, 
            { id: "noYouthLeader", question: "No. Youth Leader events (org wide)", type: "number" },
            { id: "noYouthLeaderEngage", question: "No. Youth Leaders engaged with (org wide)", type: "number" },
            { id: "noArticles", question: "No. of articles written and published in external publications (org wide)", type: "number" },
            { id: "noPodcasts", question: "No. of podcasts recorded and uploaded (org wide)", type: "number" },
            { id: "noSpeakEvent", question: "No of speaking at events (org wide)", type: "number" },
            { id: "manageAccounts", question: "Management accounts for Together Surplus/Deficit £", type: "number" },
        ]
    },
    {
        id: "Innovation",
        sections: [
            { id: "noProjectDesign", question: "No. Projects assigned Design (show last 3 months)", type: "number" },
            { id: "noCompleteDesign", question: "No. Projects completed Design (show last 3 months)", type: "number" },
            { id: "noProjectVideo", question: "No. Projects assigned Video (show last 3 months)", type: "number" },
            { id: "noCompleteVideo", question: "No. Projects completed Video (show last 3 months)", type: "number" },
            { id: "noSchoolContent", question: "No. School lessons/Assembly content produced (contrast against target)", type: "number" },
            { id: "noCommunity", question: "No. Community content produced (contrast against target)", type: "number" },
            { id: "noChurchContent", question: "No. Church content produced (contrast against target)", type: "number" },
            { id: "noUniqueProject", question: "No. Stand alone / Unique projects produced (show last 3 months)", type: "number" },
            { id: "noRequestHit", question: "ReQuest hits (unique users) (show last 3 months)", type: "number" },
            { id: "noRequestReg", question: "ReQuest no. Registered teachers (contrast against target) ", type: "number" },
            // { id: "noYouthEvents", question: "No. Youth events (org wide)", type: "number" },
            // { id: "noYoungReach", question: "No. Young people reached (org wide)", type: "number" },
            // { id: "noYoungOpport", question: "No. Young people given an opportunity to become a follower of Jesus (org wide)", type: "number" },
            // { id: "noYoungFollow", question: "No. Young people made a decision to follow Jesus (org wide)", type: "number" },
            { id: "noYouthLeader", question: "No. Youth Leader events (org wide)", type: "number" },
            { id: "noYouthLeaderEngage", question: "No. Youth Leaders engaged with (org wide)", type: "number" },
            { id: "noArticles", question: "No. of articles written and published in external publications (org wide)", type: "number" },
            { id: "noPodcasts", question: "No. of podcasts recorded and uploaded (org wide)", type: "number" },
            { id: "noTrainVideo", question: "No. Training Wall videos ", type: "number" },
            { id: "noSpeakEvent", question: "No of speaking at events (org wide)", type: "number" },
            { id: "manageAccounts", question: "Management accounts for Innovation Surplus/Deficit £", type: "number" },
        ]

    },
    {
        id: "Relational",
        sections: [
            { id: "noCentreNew", question: "No. new centres (contrast against target)", type: "number" },
            { id: "noNewCentre", question: "No. New Centre enquiries", type: "number" },
            { id: "noCentreContact", question: "No. Centre contacts (contrast against target)", type: "number" },
            { id: "noCentreVisits", question: "No. Centre visits (contrast against target)", type: "number" },
            { id: "noCentreTrustee", question: "No. Centre Trustee contacts/visits (contrast against target)", type: "number" },
            { id: "noChurchEnquiry", question: "No. Church enquiries", type: "number" },
            { id: "noChurchVisit", question: "No. Church Consultations (contrast against target)", type: "number" },
            { id: "noChurchFollowUp", question: "No. Church follow up meetings (contrast against target)", type: "number" },
            { id: "noWhyAppointment", question: "No. of Why Pack Opportunities/Appointments", type: "number" },
            { id: "noWhyPhone", question: "No. Of Why Pack phone calls", type: "number" },
            { id: "noPluseEnquiry", question: "No. Pulse enquiries", type: "number" },
            { id: "noPluseGather", question: "No. Pulse Gatherings Launched - Total (contrast against target)", type: "number" },
            { id: "noPluseLearn", question: "No. Pulse Learning Communities - Total (contrast against target)", type: "number" },
            // { id: "noColdCall", question: "No. Cold call follow ups", type: "number" },
            { id: "noPulseGatherCall", question: "No. of support calls to Pulse Gatherings", type: "number" },
            { id: "noPulseGatherVisit", question: "No. of in person visits to Pulse Gatherings", type: "number" },
            { id: "noPluseChurch", question: "No. of new churches engaged in Pulse", type: "number" },
            { id: "noUrbanEvent", question: "No. Urban Outreach events (contrast against target)", type: "number" },
            { id: "noUrbanYouthEvent", question: "No. Urban youth leader events (contrast against target)", type: "number" },
            // { id: "noYouthEvents", question: "No. Youth events (org wide)", type: "number" },
            // { id: "noYoungReach", question: "No. Young people reached (org wide)", type: "number" },
            // { id: "noYoungOpport", question: "No. Young people given an opportunity to become a follower of Jesus (org wide)", type: "number" },
            // { id: "noYoungFollow", question: "No. Young people made a decision to follow Jesus (org wide)", type: "number" },
            { id: "noYouthLeader", question: "No. Youth Leader events (org wide)", type: "number" },
            { id: "noYouthLeaderEngage", question: "No. Youth Leaders engaged with (org wide)", type: "number" },
            { id: "noArticles", question: "No. of articles written and published in external publications (org wide)", type: "number" },
            { id: "noPodcasts", question: "No. of podcasts recorded and uploaded (org wide)", type: "number" },
            { id: "noSpeakEvent", question: "No of speaking at events (org wide)", type: "number" },
            { id: "manageAccounts", question: "Management accounts for Relational Surplus/Deficit £", type: "number" },
        ]
    },
    {
        id: "Courageous",
        sections: [
            { id: "noSocialResponse", question: "No. Advocacy social media responses (contrast against target)", type: "number" },
            { id: "noPressEngage", question: "No. Advocacy Press engagements (contrast against target)", type: "number" },
            { id: "noChurchEngage", question: "No. Denominational / church advocacy relationships (contrast against target)", type: "number" },
            { id: "noNewProject", question: "No. New Projects initiated (show last 3 months)", type: "number" },
            { id: "noYouthEvents", question: "No. Youth events (org wide)", type: "number", auto: true },
            { id: "noYoungReach", question: "No. Young people reached (org wide)", type: "number", auto: true },
            { id: "noYoungOpport", question: "No. Young people given an opportunity to become a follower of Jesus (org wide)", type: "number", auto: true },
            { id: "noYoungFollow", question: "No. Young people made a decision to follow Jesus (org wide)", type: "number", auto: true },
            { id: "noYouthLeader", question: "No. Youth Leader events (org wide)", type: "number", auto: true },
            { id: "noYouthLeaderEngage", question: "No. Youth Leaders engaged with (org wide)", type: "number", auto: true },
            { id: "noJonJacketHit", question: "No. Jon Jackal music plays", type: "number" },
            { id: "noJonJacketSale", question: "No. Jon Jackal Merch sales (units) ", type: "number" },
            { id: "noJonJacketInter", question: "No. of Jon Jackal meaningful interactions", type: "number" },
            { id: "noJonJacketViews", question: "No. of Jon Jackal views", type: "number" },
            { id: "noUndergroundViews", question: "No. of Underground views", type: "number" },
            { id: "noArticles", question: "No. of articles written and published in external publications (org wide)", type: "number", auto: true },
            { id: "noPodcasts", question: "No. of podcasts recorded and uploaded (org wide)", type: "number", auto: true },
            { id: "noNewPartner", question: "No. New partnerships (org wide)", type: "number" },
            { id: "noExternal", question: "No. External appointments", type: "number" },
            { id: "noStrategic", question: "No. of strategic bodies (contrast against target)", type: "number" },
            { id: "noSpeakEvent", question: "No of speaking at events", type: "number" },
            { id: "manageAccounts", question: "Management accounts for Courageous Surplus/Deficit £", type: "number" },
        ]
    },
    {
        id: "Spiritual",
        sections: [
            { id: "noPrayerPartner", question: "No. Prayer partners (contrast against target)", type: "number" },
            { id: "noPrayerInitiative", question: "No. Prayer initiatives (org wide)", type: "number" },
            { id: "noCentreEngage", question: "No. Centres engaged in research", type: "number" },
            { id: "researchProcess", question: "Centre research process (begun for the month)", type: "number" },
            { id: "researchLaunch", question: "Centre research launched (complete that month)", type: "number" },
            { id: "interResearchProcess", question: "International research process (begun for the month)", type: "number" },
            { id: "interResearchLaunch", question: "International research launched (complete for the month)", type: "number" },
            { id: "interContact", question: "International contact support for nations (nations given any kind of assistance)", type: "number" },
            { id: "resourceAvail", question: "Resources made available to nations (for the month)", type: "number" },
            { id: "resourceEnquiry", question: "Resources enquiry for International (for the month)", type: "number" },
            { id: "resourceInterProcess", question: "Resources for International process (begun for the month)", type: "number" },
            { id: "resourceInterLaunch", question: "Resources for International launched (complete for the month)", type: "number" },
            // { id: "noYouthEvents", question: "No. Youth events (org wide)", type: "number" },
            // { id: "noYoungReach", question: "No. Young people reached (org wide)", type: "number" },
            // { id: "noYoungOpport", question: "No. Young people given an opportunity to become a follower of Jesus (org wide)", type: "number" },
            // { id: "noYoungFollow", question: "No. Young people made a decision to follow Jesus (org wide)", type: "number" },
            { id: "noYouthLeader", question: "No. Youth Leader events (org wide)", type: "number" },
            { id: "noYouthLeaderEngage", question: "No. Youth Leaders engaged with (org wide)", type: "number" },
            { id: "noArticles", question: "No. of articles written and published in external publications (org wide)", type: "number" },
            { id: "noPodcasts", question: "No. of podcasts recorded and uploaded (org wide)", type: "number" },
            { id: "noSpeakEvent", question: "No of speaking at events (org wide)", type: "number" },
            { id: "manageAccounts", question: "Management accounts for Spiritual Surplus/Deficit £", type: "number" },
        ]
    },
]

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];